import React from 'react';
import PropTypes from "prop-types";


export default class ReactContactMapItem extends React.Component {

    constructor(props) {
        super(props);
    }




    render() {
        let distance = this.props.distance != null ? <p className="m-react-contact-map__item-distance">{'ca. ' + this.props.distance + ' km von ' + this.props.selectedZip}</p> : null,
            address = this.props.data && this.props.renderAddressFunc(this.props.data) ?<p className="m-react-contact-map__item-location">{this.props.renderAddressFunc(this.props.data)}</p> : '',
            tel = this.props.data.tel ?  <p className="m-react-contact-map__item-tel">{this.props.data.tel}</p> : '',
            mail = this.props.data.mail ? <p className="m-react-contact-map__item-mail">{this.props.data.mail}</p> : '',
            category = this.props.data.category ?  <p className="m-react-contact-map__item-category">{this.props.data.category}</p> : '',
            teaserText = this.props.data.teaserText ? <p className="m-react-contact-map__item-teaser-text">{this.props.data.teaserText}</p>: '';

        return(
            <div className="m-react-contact-map__item ">
                <a href={this.props.data.infoLink} className="m-react-contact-map__item-link" title={'Zu: ' + this.props.data.name} aria-label={'Zu: ' + this.props.data.name} />
                <div className="m-react-contact-map__item-inner">
                    <div className="m-react-contact-map__item-text">
                        <div className="m-react-contact-map__item-info-wrapper">
                            <div className="m-react-contact-map__item-info-container">
                                <h3 className="m-react-contact-map__item-title">{this.props.data.name}</h3>
                                {category}
                            </div>
                            <div className="m-react-contact-map__item-info-container">
                                {address}
                                {distance}
                                {tel}
                                {mail}
                            </div>
                            <div className="m-react-contact-map__item-info-container">
                                {teaserText}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

ReactContactMapItem.propTypes = {
    data: PropTypes.shape({
            name: PropTypes.string,
            category: PropTypes.string,
            infoLink: PropTypes.string,
            startDate: PropTypes.string,
            zip: PropTypes.string,
            city:  PropTypes.string,
            lat: PropTypes.number,
            lng: PropTypes.number
        }
    ),
    isActive: PropTypes.bool,
    distance: PropTypes.number,
    selectedZip: PropTypes.string
};


