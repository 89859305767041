import "velocity-animate/velocity.min";

let $b,
    $modules,
    $accessibleAccordions,
    $accordionTriggers,
    $expandAllTriggers,
    animationDuration,
    urlInitDone;

$b = $('body');
$modules = $b.find('.js-module-accessible-accordion:not(.js-module-accessible-accordion--initialized)');
$accessibleAccordions = $b.find('.js-accessible-accordion');
$accordionTriggers = $accessibleAccordions.find('.js-accessible-accordion-trigger');
$expandAllTriggers = $b.find('.js-expand-all');
animationDuration = 200;
urlInitDone = false;

if($modules.length > 0) {



    $modules.each(function() {

        var $thisModule = $(this);

        _checkOpenAccordions($thisModule);

        $thisModule.on('_accordionStateChanged', function () {

            _checkOpenAccordions($thisModule);

        });

        $thisModule.addClass('js-module-accessible-accordion--initialized');

    });

}

if($expandAllTriggers.length > 0) {

    $(document).on('keydown',function(e) {

        if((e.metaKey || e.ctrlKey) && (e.keyCode || e.which) === 70){

            _openAccordions($accessibleAccordions);

            $modules.each(function() {

                var $thisModule = $(this);

                _checkOpenAccordions($thisModule);

            });

        }

    });

    $expandAllTriggers.on('click', function () {

        var $thisTrigger = $(this),
            $thisModule = $thisTrigger.closest('.js-module-accessible-accordion'),
            $thisAccordions = $thisModule.find('.js-accessible-accordion'),
            $thisAccordionTriggers = $thisAccordions.find('.js-accessible-accordion-trigger');

        if(!$thisTrigger.hasClass('js-expanded')){

            $thisTrigger.addClass('js-expanded');
            _openCloseAccordion($thisAccordionTriggers);

        }

        else {

            $thisTrigger.removeClass('js-expanded');
            _openCloseAccordion($thisAccordionTriggers);

        }

    });

}

if($accessibleAccordions.length > 0) {

    //event listener
    //triggers _checkOpenAccordions when accordion trigger is clicked
    $accordionTriggers.on('click', function () {

        var $thisModule = $(this).closest('.js-module-accessible-accordion');

        _openCloseAccordion($(this));
        $thisModule.trigger('_accordionStateChanged');

    });


}

function _checkOpenAccordions($module){


    var windowHash = window.location.hash,
        $matchingAccordion = $module.find(windowHash).parents('.js-accessible-accordion');

    if($matchingAccordion.length > 0 && !urlInitDone) {

        _openAccordions($matchingAccordion);
        urlInitDone = true;

    }

    var $thisExpandAllTrigger = $module.find('.js-expand-all'),
        $thisOpenAccessibleAccordions = $module.find('.js-accessible-accordion.js-open');

    if($thisOpenAccessibleAccordions.length > 0){

        $thisExpandAllTrigger.addClass('js-expanded');

    } else {

        $thisExpandAllTrigger.removeClass('js-expanded');

    }

}

//event handler
function _openCloseAccordion($accordionTriggers) {

    var $accordionWrapper = $accordionTriggers.closest('.js-accessible-accordion'),
        $accordionContent = $accordionWrapper.find('.js-accessible-accordion-content');

    if($accordionWrapper.hasClass('js-open')) {

        $accordionContent.velocity('slideUp', {

            duration: animationDuration,
            begin: function() {

                $accordionWrapper.removeClass('js-slide-done');
                $accordionWrapper.removeClass('js-open');
                $accordionTriggers.attr('aria-expanded', false);
                $accordionContent.attr('aria-hidden', true);

            }

        });

    }

    else {

        $accordionContent.velocity('slideDown', {

            duration: animationDuration,
            begin: function() {

                $accordionWrapper.addClass('js-open');
                $accordionTriggers.attr('aria-expanded', true);
                $accordionContent.attr('aria-hidden', false);

            },
            complete: function () {

                $accordionWrapper.addClass('js-slide-done');

            }

        });

    }

}

function _openAccordions($thisAccordions) {

    $thisAccordions.each(function () {

        var $thisAccordionWrapper = $(this),
            $thisAccordionTrigger = $thisAccordionWrapper.find('.js-accessible-accordion-trigger'),
            $thisAccordionContent = $thisAccordionWrapper.find('.js-accessible-accordion-content');

        if(!$thisAccordionWrapper.hasClass('js-open')) {
            $thisAccordionContent.velocity('slideDown', {

                duration: animationDuration,
                begin: function() {

                    $thisAccordionWrapper.addClass('js-open');
                    $thisAccordionTrigger.attr('aria-expanded', true);
                    $thisAccordionContent.attr('aria-hidden', false);

                },
                complete: function () {

                    $thisAccordionWrapper.addClass('js-slide-done');

                }

            });
        }

    });

}
