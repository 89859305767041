handleYoutubeApiCb();

window.isYouTubeIframeAPIReady = false;
let event = new CustomEvent("youTubeIframeAPIReady", { "detail": "triggers player init in youtube-video.js" });

window.onYouTubeIframeAPIReady = function() {
    window.isYouTubeIframeAPIReady = true;
    window.dispatchEvent(event);
};


let $b,
    $component;

$b = $('body');
$component = $b.find('.js-youtube-video:not(.js-youtube-video--initialized)');

if($component.length > 0) {
    waitForCookieSettingsManager();
}

function init() {
    $.getScript("https://www.youtube.com/iframe_api");

    $component.each(function () {
        let $this = $(this),
            $thisYouTubeVideo = $this.find('.js-youtube-video-youtube'),
            thisYouTubeVideoDataId = $thisYouTubeVideo.data('video-id');

        $component.addClass('js-youtube-video--initialized');

        // youtube init
        if($thisYouTubeVideo.length) {

            function initYT() {

                let player;

                player = new YT.Player($thisYouTubeVideo.get(0), {
                    host: 'https://www.youtube-nocookie.com',
                    playerVars: {'color':'white', 'fs':0, 'playsinline':1, 'rel':0, 'showinfo':0, 'origin':location.origin},
                    videoId: thisYouTubeVideoDataId
                });
                $component.addClass('js-revoke-open');

            }

            // test if flag was already set (and therefore event was already dispatched)
            if(!window.isYouTubeIframeAPIReady) {

                // flag was not set yet, therefore we register the event handler
                $(window).on('youTubeIframeAPIReady', initYT);

            }

            else {

                // event was already dispatched, so simply call our init function
                initYT();

            }

        }
    })
}
function destroy(){

    $component.each(function (){

        let $this = $(this),
            $thisYouTubeVideo = $this.find('.js-youtube-video-youtube'),
            thisYouTubeVideoDataId = $thisYouTubeVideo.data('video-id');

        $this.removeClass('js-revoke-open');
        $thisYouTubeVideo.replaceWith('<div data-video-id="' + thisYouTubeVideoDataId + '" class="c-youtube-video__video-youtube js-youtube-video-youtube"></div>');

    });

}

function waitForCookieSettingsManager(){

    if(typeof window.CookieSettingsManager === "object"){

        bindCookieCategory('comfort');

    }

    else{

        setTimeout(waitForCookieSettingsManager, 250);

    }
}

//bind category that needs to be watched for changes
function bindCookieCategory(category){

    //check if category is initialy approved
    if(window.CookieSettingsManager.getStatusForCategory(category)) {

        init();

    }

    else {
        destroy();

    }

    //triggered in cookie-settings-manager.min
    document.addEventListener('onCookieSettingsChanged', function(event){
        if(event.detail.cookieSettings[category] && event.detail.cookieSettings[category].approved) {

            //init if category is approved while runtime
            init();

        }

        else {
            //destroy if category is rejected while runtime
            destroy();

        }

    });

}

//YouTube API Callback handling
function handleYoutubeApiCb() {
    if (
        typeof window.CustomEvent === "function" ||
        // In Safari, typeof CustomEvent == 'object' but it otherwise works fine
        this.CustomEvent.toString().indexOf('CustomEventConstructor')>-1
    ) { return; }

    function CustomEvent ( event, params ) {
        params = params || { bubbles: false, cancelable: false, detail: undefined };
        let evt = document.createEvent( 'CustomEvent' );
        evt.initCustomEvent( event, params.bubbles, params.cancelable, params.detail );
        return evt;
    }

    CustomEvent.prototype = window.Event.prototype;

    window.CustomEvent = CustomEvent;
}