import React from 'react';
import PropTypes from "prop-types";


export default class ReactNewsOverviewItem extends React.Component {

    constructor(props) {
        super(props);
        this.renderImage = this.renderImage.bind(this);
    }


    renderImage() {
        if(this.props.data.image) {
            let copyright = this.props.data.image.copyright ? <div className="c-figure__copyright">&copy; {this.props.data.image.copyright}</div> : "",
                mobileImg = this.props.data.image.srcsetUrlMobile ? <source srcSet={this.props.data.image.srcsetUrlMobile} media="(max-width: 520px)"/> : "",
                tabletImg = this.props.data.image.srcsetUrlTablet ? <source srcSet={this.props.data.image.srcsetUrlTablet} media="(max-width: 980px)"/> : "",
                netbookImg = this.props.data.image.srcsetUrlNetbook ? <source srcSet={this.props.data.image.srcsetUrlNetbook} media="(max-width: 1360px)"/> : "",
                laptopImg = this.props.data.image.srcsetUrlLaptop ? <source srcSet={this.props.data.image.srcsetUrlLaptop} media="(max-width: 1580px)"/> : "",
                desktopImg = this.props.data.image.srcsetUrlDesktop ? <source srcSet={this.props.data.image.srcsetUrlDesktop} media="(max-width: 1921px)"/> : "",
                cinemaImg = this.props.data.image.srcsetUrlCinema ? <source srcSet={this.props.data.image.srcsetUrlCinema} media="(min-width: 1922px)"/> : "";
            return (
                <div className="c-news-list__item-image">
                    <figure className="c-figure ">
                        <div className="c-figure__inner">
                            <picture>
                                {mobileImg}
                                {tabletImg}
                                {netbookImg}
                                {laptopImg}
                                {desktopImg}
                                {cinemaImg}
                                <img srcSet={this.props.data.image.url} alt={this.props.data.image.alt}/>
                            </picture>
                            {copyright}
                        </div>
                    </figure>
                </div>
            )
        }
    }

    render() {
        let noImageClass = this.props.data.image ? "" : "c-news-list__item--no-image",
            description = this.props.data.description ? <div className="c-news-list__item-description"><p>{this.props.data.description}</p></div> : "";
        return(
            <li className={"c-news-list__item " + noImageClass}>
                <a href={this.props.data.href} className="c-news-list__item-link" title={"Zur Detailseite: " + this.props.data.title} aria-label={"Zur Detailseite: " + this.props.data.title}/>
                <div className="c-news-list__item-inner">
                    {this.renderImage()}
                    <p className="c-news-list__item-date">
                        {this.props.data.date}
                    </p>
                    <div className="c-news-list__item-title">
                        <h3>{this.props.data.title}</h3>
                    </div>
                    {description}
                </div>
            </li>
        )
    }
}

ReactNewsOverviewItem.propTypes = {
    data: PropTypes.shape({
        title: PropTypes.string,
        date: PropTypes.string,
        description: PropTypes.string,
        href: PropTypes.string,
        image: PropTypes.shape({
            url: PropTypes.string,
            alt: PropTypes.string,
            srcsetUrlMobile: PropTypes.string,
            srcsetUrlTablet: PropTypes.string,
            srcsetUrlNetbook: PropTypes.string,
            srcsetUrlLaptop: PropTypes.string,
            srcsetUrlDesktop: PropTypes.string,
            srcsetUrlCinema: PropTypes.string
        })
    })
};


