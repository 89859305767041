import {getViewport, whichTransitionEndEvent} from "../../javascript/helpers";
import {breakpoints, transitionDurations} from "../../javascript/config";
import "velocity-animate/velocity.min";

const $h = $(".js-header");

if ($h.length > 0) {
    const $w = $(window);
    const $b = $('body');
    const navLevelClass = ".js-nav-level";
    const navItemClass = ".js-nav-item";
    const navLevelTriggerClass = ".js-nav-level-trigger";
    const navLevelOpenClassName = "js-open";
    const headerOpenNavClassName = "js-nav-open";
    const $hamburger = $h.find('.js-hamburger');
    const $nav = $h.find('.js-nav');
    const $navWrapper = $nav.find('.js-nav-wrapper');
    const $subnavTrigger = $nav.find(navLevelTriggerClass);

    let resizeTimeout = null;
    let state = {
        navIsOpen: false
    };

    let viewport = getViewport();

    if (viewport.width <= breakpoints.menubreak) {
        $navWrapper.attr('aria-hidden', true);
    }
    else {
        $navWrapper.attr('aria-hidden', false);
    }


    $b.on('NavIsOpen', function() {
        $b.on('click.handleNavClose', function(e){
            let $target = $(e.target);

            if($h.find($target).length === 0) {

                closeAllSublevels($h);
                $b.off('click.handleNavClose');
            }
        });
    });

    $w.on('resize', function () {
        clearTimeout(resizeTimeout);
        resizeTimeout = setTimeout(() => {

            closeAllSublevels($h);

            viewport = getViewport();
            if (viewport.width <= breakpoints.menubreak) {
                $navWrapper.attr('aria-hidden', true);
            }
            else {
                $h.find(navLevelClass).attr('style', ''); //remove any styles velocity might have added
                $navWrapper.attr('aria-hidden', false);
            }
        }, 100);
    });

    $subnavTrigger.on('click', function (e) {

        let $subNavTrigger = $(this),
            $navItem = $subNavTrigger.closest(navItemClass);

        let $openSiblingSublevels = $navItem.siblings(navItemClass).filter(function(){
                return $(this).find('.' + navLevelOpenClassName).length > 0;
            });

        if ($subNavTrigger.hasClass(navLevelOpenClassName)) {

            //close this layer
            //close all sublevels as well
            closeAllSublevels($navItem);

        }
        else {
            $subNavTrigger.attr('aria-expanded', true);
            $subNavTrigger.next(navLevelClass).attr('aria-hidden', false);
            $h.addClass(headerOpenNavClassName);

            //close other levels that are already open
            if($openSiblingSublevels.length > 0) {
                closeAllSublevels($openSiblingSublevels);
            }
            if (viewport.width > breakpoints.menubreak) {
                $subNavTrigger.addClass(navLevelOpenClassName);
            } else {
                $subNavTrigger.next(navLevelClass).velocity('slideDown', {
                    duration: transitionDurations.medium,
                    complete: function () {
                        $subNavTrigger.addClass(navLevelOpenClassName);
                    }
                });
            }

            state.navIsOpen = true;
            $b.trigger('NavIsOpen');

        }
    });

    $hamburger.on('click', function () {
        if (state.navIsOpen === false) {

            state.navIsOpen = true;
            $b.trigger('NavIsOpen');
            $b.css('overflow', 'hidden');
            $h.addClass(headerOpenNavClassName);
            $hamburger.addClass('js-active');
            $hamburger.attr('aria-label', 'Navigation schließen');
            $hamburger.attr('aria-expanded', true);
            $navWrapper.attr('aria-hidden', false);

        } else {
            $hamburger.attr('aria-label', 'Navigation öffnen');
            closeAllSublevels($h, true);
        }

    });


    function closeAllSublevels($container, hamburgerClick) {
        if ($container) {

            let $navLevelTriggers = $container.find('.'+navLevelOpenClassName),
                $navLevels = $navLevelTriggers.next(navLevelClass);


            $navLevelTriggers.removeClass(navLevelOpenClassName);
            $navLevelTriggers.attr('aria-expanded', false);
            $navLevels.attr('aria-hidden', true);

            //close all sublevels
            if (viewport.width <= breakpoints.menubreak) {
                if(hamburgerClick) {
                    $navWrapper.attr('aria-hidden', true);
                    $hamburger.attr('aria-expanded', false);
                    $navWrapper.one(whichTransitionEndEvent(), function(){
                        $navLevels.velocity('slideUp', {
                            duration: transitionDurations.medium
                        });
                    });
                    handleMenuCompletelyClosed();
                }
                else {
                    $navLevels.velocity('slideUp', {
                        duration: transitionDurations.medium
                    });
                }
            }
            else {
                //hide Menu
                if ($h.find('.'+navLevelOpenClassName).length === 0) {
                    handleMenuCompletelyClosed();
                }
            }
        }
    }

    function handleMenuCompletelyClosed() {
        state.navIsOpen = false;
        $b.trigger('NavIsClosed');
        $h.removeClass(headerOpenNavClassName);
        $hamburger.removeClass('js-active');
        $b.css('overflow', 'visible');
    }
}

