import React from "react";
import ReactDOM from 'react-dom';
import ReactNewsOverview from "./reactComponents/ReactNewsOverview";


let $b = $('body'),
    $reactWrapper = $b.find('.js-m-react-news-overview .js-react-wrapper');

$reactWrapper.each(function(index){
    initReactNewsOverview(this, index);
});

function initReactNewsOverview(reactContainer, index) {
    let $reactContainer = $(reactContainer),
        $currentModule = $reactContainer.parents('.js-m-react-news-overview'),
        dataUrl = $reactContainer.data('url'),
        loadedData = [];

    $.when(
        $.getJSON(dataUrl, function(data){
            loadedData = data;
        }).fail(function( jqxhr, textStatus, error ) {
            let err = textStatus + ", " + error;
            console.log( "Request Failed: " + err );
        })
    ).then(function() {
        ReactDOM.render(
            <ReactNewsOverview
                data={loadedData}
                moduleIndex = {index}
            />
            , reactContainer);
        $currentModule.removeClass('js-loading');
    });

}

