import React from 'react';
import {GoogleMap, LoadScript, Marker, InfoWindow, MarkerClusterer} from '@react-google-maps/api';
import PropTypes from "prop-types";

export default class ReactEventMapMap extends React.Component {
    constructor(props) {
        super(props);
        this.renderMarkers = this.renderMarkers.bind(this);
        this.loadHandler = this.loadHandler.bind(this);
        this.initFitBounds = this.initFitBounds.bind(this);
        this.checkForActiveMarker = this.checkForActiveMarker.bind(this);
        this.renderMarkerWindowEvents = this.renderMarkerWindowEvents.bind(this);
        this.renderActiveMarkerWithInfo = this.renderActiveMarkerWithInfo.bind(this);
        this.renderSingleMarker = this.renderSingleMarker.bind(this);
        this.mapInstance = null;
        this.updatingKey = 1;
    }

    componentDidUpdate(prevProps) {
        //Fit bounds to new markers/clusters, after filtering and clustering
        if(this.mapInstance !== null && prevProps.markerData.length !== this.props.markerData.length) {
            this.initFitBounds();
        }
    }


    /**
     *
     * @param map
     * @effect - saves map instance to global variable this.mapInstance & triggers fitBounds
     */
    loadHandler(map) {
        this.mapInstance = map;
        this.initFitBounds();
    }

    /**
     * @effect - maps through markers and extends const 'bounds' before triggering fitbounds on this.mapInstance with const 'bounds'
     */
    initFitBounds() {
        const bounds = new window.google.maps.LatLngBounds();

        this.props.markerData.map(item => {
            if(item.lat && item.lng) {
                bounds.extend({lat: Number(item.lat), lng: Number(item.lng)});
            }
            return item.id;
        });

        this.mapInstance.fitBounds(bounds);
    }

    /**
     * @returns {number}
     * @effect - compares all markers to active Id and returns index of active marker
     */
    checkForActiveMarker() {
        for(let i = 0; i < this.props.markerData.length ; i++) {
            if(this.props.markerData[i].id === this.props.activeMarkerId ) {
                return i;
            }
        }
    }

    /**
     *
     * @param itemData
     * @param fillColor
     * @param strokeColor
     * @param strokeOpacity
     * @param index
     * @param infoWindow
     * @param clusterer
     * @returns {*}
     * @effect - returns marker markup
     */

    renderSingleMarker(itemData, fillColor, strokeColor, strokeOpacity, index, infoWindow, clusterer) {
        if(itemData.events && itemData.lat && itemData.lng) {

            return (
                <Marker
                    position = {{lat: Number(itemData.lat), lng: Number(itemData.lng)}}
                    icon = {{
                        path: "M100,8.616A54.03,54.03,0,0,0,45.971,62.645C45.971,92.485,100,186.718,100,186.718s54.029-94.233,54.029-124.073A54.03,54.03,0,0,0,100,8.616ZM100,89a24.362,24.362,0,1,1,24.362-24.362A24.362,24.362,0,0,1,100,89Z",
                        fillColor: fillColor,
                        fillOpacity: 1,
                        anchor: {x: 100, y: 185}, //specific coordinates for this path only
                        strokeWeight: 2,
                        strokeColor: strokeColor,
                        strokeOpacity: strokeOpacity,
                        scale: .3
                    }}
                    title = {itemData.events[0].street + ', ' + itemData.events[0].zip + ' ' + itemData.events[0].city}
                    key = {index}
                    clusterer = {clusterer}
                    onClick={() => this.props.handleMarkerClick(itemData)}
                >
                    {infoWindow}

                </Marker>
            )

        }

        else if (itemData.lat && itemData.lng) {

            return (
                <Marker
                    position = {{lat: Number(itemData.lat), lng: Number(itemData.lng)}}
                    icon = {{
                        path: "M100,8.616A54.03,54.03,0,0,0,45.971,62.645C45.971,92.485,100,186.718,100,186.718s54.029-94.233,54.029-124.073A54.03,54.03,0,0,0,100,8.616ZM100,89a24.362,24.362,0,1,1,24.362-24.362A24.362,24.362,0,0,1,100,89Z",
                        fillColor: fillColor,
                        fillOpacity: 1,
                        anchor: {x: 100, y: 185}, //specific coordinates for this path only
                        strokeWeight: 2,
                        strokeColor: strokeColor,
                        strokeOpacity: strokeOpacity,
                        scale: .3
                    }}
                    title = {itemData.city}
                    key = {index}
                    clusterer = {clusterer}
                    onClick={() => this.props.handleMarkerClick(itemData)}
                >
                    {infoWindow}

                </Marker>
            )

        }
    }

    renderMarkerWindowEvents(markerData) {

        if (markerData.events) {
            let eventMarkup = [];

            markerData.events.map((event, i) => {
                eventMarkup.push(
                    <div className="m-react-event-map__item" key={i}>
                        <div className="m-react-event-map__item-date">
                            {this.props.renderDateFunc(event.date)}
                        </div>
                        <div className="m-react-event-map__item-text">
                            <h3 className="m-react-event-map__item-title"><a href={event.infoLink}>{event.name}</a></h3>
                            <div className="m-react-event-map__item-info-wrapper">
                                <div className="m-react-event-map__item-info-container">
                                    <p className="m-react-event-map__item-category">
                                        {event.category}
                                    </p>
                                </div>
                                <div className="m-react-event-map__item-info-container">
                                    <p className="m-react-event-map__item-location">
                                    <span>
                                        {this.props.renderAddressFunc(event)}
                                    </span>
                                    </p>
                                </div>
                                <div className="m-react-event-map__item-info-container">
                                    <p className="m-react-event-map__item-host">
                                    <span>
                                        {event.host}
                                    </span>
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                )
            });

            return (
                <React.Fragment>
                    {eventMarkup}
                </React.Fragment>
            );
        }

        else {

            return (
                <div className="m-react-event-map__item ">
                    <div className="m-react-event-map__item-date">
                        {this.props.renderDateFunc(markerData.date)}
                    </div>
                    <div className="m-react-event-map__item-text">
                        <h3 className="m-react-event-map__item-title"><a href={markerData.infoLink}>{markerData.name}</a></h3>
                        <div className="m-react-event-map__item-info-wrapper">
                            <div className="m-react-event-map__item-info-container">
                                <p className="m-react-event-map__item-category">
                                    {markerData.category}
                                </p>
                            </div>
                            <div className="m-react-event-map__item-info-container">
                                <p className="m-react-event-map__item-location">
                                    <span>
                                        {this.props.renderAddressFunc(markerData)}
                                    </span>
                                </p>
                            </div>
                            <div className="m-react-event-map__item-info-container">
                                <p className="m-react-event-map__item-host">
                                    <span>
                                        {markerData.host}
                                    </span>
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            )

        }

    }

    /**
     * @param markerData
     * @returns {*}
     * @effect -  creates InfoWindow before returning marker markup
     */
    renderActiveMarkerWithInfo(markerData) {
        if(markerData !== null) {
            let infoWindow = <InfoWindow
                    position={{lat: Number(markerData.lat), lng: Number(markerData.lng)}}
                    onCloseClick={() => this.props.resetActiveMarker()}
                    options={{
                        alignTop: true,
                        pane: 'mapPane',
                        pixelOffset: new window.google.maps.Size(0, -20),
                        boxStyle: {
                            width: '300px'
                        }
                    }}
                >
                {this.renderMarkerWindowEvents(markerData)}

                </InfoWindow>;

            return this.renderSingleMarker(markerData, '#F39200', '#F39200', 1, this.props.markerData.length, infoWindow);
        }
    }

    /**
     * @returns {*}
     * @effect - returns optional active marker and markerClusterer
     */
    renderMarkers() {
        let activeMarkerData = null;

        if(this.props.activeMarkerId) {

            let activeMarkerIndex = this.checkForActiveMarker();

            activeMarkerData = this.props.markerData[activeMarkerIndex];
        }
        return (
            <React.Fragment>

                {this.renderActiveMarkerWithInfo(activeMarkerData)}
                <MarkerClusterer
                    options = {{
                        imagePath: this.props.clusterMarkerPath,
                        imageSizes: [40, 50, 60, 70, 80]
                    }}
                    maxZoom = {14}
                >
                    {
                        (clusterer) => {
                            return this.props.markerData.map((item, index) => {

                                return this.renderSingleMarker(item, '#009640','#009640', 0, index, null, clusterer);

                            })
                        }
                    }
                </MarkerClusterer>
            </React.Fragment>

        )

    }

    render() {
        return (
            <LoadScript
                googleMapsApiKey={this.props.apiKey}
            >
                <GoogleMap
                    mapContainerClassName = "m-react-event-map__map"
                    defaultZoom={12}
                    defaultCenter={{lat:48.806849, lng:9.215532}}
                    options = {{
                        zoomControl: true,
                        mapTypeControl: false,
                        scaleControl: false,
                        streetViewControl: false,
                        rotateControl: false,
                        fullscreenControl: false,
                        maxZoom: 15
                    }}
                    onLoad = {map => this.loadHandler(map)}
                    key={this.updatingKey}
                >
                    {this.renderMarkers()}
                </GoogleMap>
            </LoadScript>

        )
    }
}

ReactEventMapMap.propTypes = {
    markerData: PropTypes.arrayOf(PropTypes.shape({
        category: PropTypes.string,
        infoLink: PropTypes.string,
        startDate: PropTypes.string,
        id: PropTypes.number,
        zip: PropTypes.string,
        city:  PropTypes.string,
        lat: PropTypes.number,
        lng: PropTypes.number
    })),
    clusterMarkerPath: PropTypes.string,
    handleMarkerClick: PropTypes.func,
    resetActiveMarker: PropTypes.func,
    apiKey: PropTypes.string,
    activeMarkerId: PropTypes.number
};
