import React from 'react';
import Select from "react-select";

export default class ReactFilterSelect extends React.Component {
    constructor(props) {
        super(props);
    }
    render() {
        const formfieldClass = this.props.formfieldClass ? 'ph-formfield ' + this.props.formfieldClass : 'ph-formfield';

        return(
            <div className={formfieldClass} key={this.props.filterIndex}>
                {this.props.label}
                <div className="ph-formfield__select-wrap">
                    <Select
                        inputId = {this.props.inputID}
                        options = {this.props.options}
                        placeholder = {this.props.placeholder}
                        isMulti = {this.props.multiSelect}
                        className = "select"
                        isClearable = {true}
                        isSearchable = {true}
                        classNamePrefix = "select"
                        value = {this.props.value}
                        openMenuOnFocus = {true}
                        onChange = {(selectedOption) => this.props.handleSelectChange(this.props.multiSelect, this.props.filterIndex, this.props.inputID, selectedOption ? selectedOption : null, this.props.handleFilterChange, this.props.customChangeFunction ? this.props.customChangeFunction(this.props.filterIndex) : null, this.props.dependentFilterId)}
                        styles = {this.props.styles}
                        isDisabled = {this.props.disabled}
                    />
                </div>
            </div>
        )
    }
}
