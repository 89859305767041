import React from 'react';
import PropTypes from "prop-types";
import ReactNewsOverviewItem from "./ReactNewsOverviewItem";

import ReactPagination from "../../../components/react-pagination/ReactPagination";


export default class ReactNewsOverview extends React.Component {

    constructor(props) {
        super(props);

        this.pagebrowserSessionName = "dvnlp-react-newsOverviewPageIndex";
        this.moduleName = "react-news-overview";
        this.data = this.props.data;
        this.itemsToShow = this.filteredItems;
        this.pagebrowserDefault = 0;


        //check if active pagebrowser page was saved to session
        if(sessionStorage.getItem(this.pagebrowserSessionName)) {
            this.pagebrowserDefault = JSON.parse(sessionStorage.getItem(this.pagebrowserSessionName));
        }

        this.state = {
            activePage: this.pagebrowserDefault,
            activeItem: null,
            viewportHeight: 0,
            viewportWidth: 0
        };


        //bind functions from this parent component
        this.renderListItems = this.renderListItems.bind(this);

        this.handlePagebrowserClick = this.handlePagebrowserClick.bind(this);
        this.renderPagebrowser = this.renderPagebrowser.bind(this);
        this.checkActivePageOutsideRange = this.checkActivePageOutsideRange.bind(this);
        this.updateWindowDimensions = this.updateWindowDimensions.bind(this);

        //create Refs
        this.itemWrapperRef = React.createRef();

    }

    componentDidMount() {
        this.updateWindowDimensions();
        window.addEventListener('resize', this.updateWindowDimensions);
        window.addEventListener('resize', this.checkActivePageOutsideRange);
    }
    componentWillUnmount() {
        window.removeEventListener('resize', this.updateWindowDimensions);
        window.removeEventListener('resize', this.checkActivePageOutsideRange);
    }

    checkActivePageOutsideRange() {
        if(this.itemsToShow.length === 0 && this.state.activePage > 0) {
            this.setState({
                activePage : this.state.activePage - 1
            });
        }
    }

    /**
     * check viewport and save to state
     * @effect - sets state viewportWidth & viewportHeight to current values
     */
    updateWindowDimensions() {
        this.setState({
            viewportWidth: window.innerWidth,
            viewportHeight: window.innerHeight
        });
    }

    /**
     *
     * @param newActivePage = type number, index of next active page
     * @effect - scrolls to list, new active page to session, sets state activePage to new active page (index)
     */
    handlePagebrowserClick(newActivePage) {
        //scroll to top of list on page-switch
       // $(this.itemWrapperRef.current).phScrollTo(-40, 600); //@ToDoFe - enable scroll to top of list on pagination click
        sessionStorage.setItem(this.pagebrowserSessionName, newActivePage);

        this.setState({
            activePage: newActivePage
        });
    }

    /**
     * renders list items
     * @returns {*}
     * @effect - returns markup for all items
     */
    renderListItems(){

        return this.itemsToShow.map((item, index) => {
            return (
                <ReactNewsOverviewItem
                    data={item}
                    key={index}
                />
            )
        })
    }

    /**
     *
     * @param pageAmount = int
     * @returns {*}
     * @effect - render pagination for items
     */
    renderPagebrowser(pageAmount) {
        if(pageAmount > 1) {
            return(
                <ReactPagination
                    pageAmount = {pageAmount}
                    activePage = {this.state.activePage}
                    handlePagebrowserClick = {this.handlePagebrowserClick}
                />
            )
        }
    }

    render() {
        this.visibleItems = 10;


        let pageAmount = Math.ceil(this.data.length / this.visibleItems),
            maxItemToShowNumber = (this.state.activePage + 1) * this.visibleItems,
            minItemToShowNumber = maxItemToShowNumber - this.visibleItems + 1,
            minItemToShowIndex = minItemToShowNumber - 1;


    //slice before minItemToShowIndex and before maxItemToShowNumber - NOT index, as it is sliced BEFORE the given index
        this.itemsToShow = this.data.slice(minItemToShowIndex, maxItemToShowNumber);

        return(
            <div className="wrapper">
                <div className="m-react-news-overview__inner">
                    <div className="m-react-news-overview__content">

                        <ul className="c-news-list"  ref={this.itemWrapperRef}>
                            {this.renderListItems()}
                        </ul>
                        {this.renderPagebrowser(pageAmount)}
                    </div>
                </div>
            </div>
        );
    }
}

ReactNewsOverview.propTypes = {
    data: PropTypes.arrayOf(PropTypes.shape({
        title: PropTypes.string,
        date: PropTypes.string,
        description: PropTypes.string,
        href: PropTypes.string,
        image: PropTypes.shape({
            url: PropTypes.string,
            alt: PropTypes.string,
            srcsetUrlMobile: PropTypes.string,
            srcsetUrlTablet: PropTypes.string,
            srcsetUrlNetbook: PropTypes.string,
            srcsetUrlLaptop: PropTypes.string,
            srcsetUrlDesktop: PropTypes.string,
            srcsetUrlCinema: PropTypes.string
        })
    }))
};
