import {getViewport} from "../../../javascript/helpers";
import {breakpoints} from "../../../javascript/config";

let $w,
    $b,
    $h,
    headerHeight,
    $component,
    $nav,
    $trigger,
    viewportWidth,
    viewportHeight;

$b = $('body');
$w = $(window);
$h = $b.find('.js-header');
headerHeight = $h.outerHeight();
$component = $b.find('.js-c-toc:not(.js-c-toc--initialized)');
$nav = $component.find('.js-nav');
$trigger = $component.find('.js-trigger');

updateViewport();

if($component.length > 0) {

   let $items = $component.find('.js-item');

    handleWindowScroll($component);

    $w.on('resize', function(){
        headerHeight = $h.outerHeight();
        updateViewport();
        $w.off('scroll.cToc');
        closeToc($component, $trigger);
        handleWindowScroll($component);
    });

   $items.on('click', function(){
      if($component. hasClass('js-open')){
          closeToc($component, $trigger);
      }
   });

   $b.on('NavIsOpen', function(){
      closeToc($component, $trigger);
   });

   $trigger.on('click', function(){
       if($component.hasClass('js-no-transition')) {
           $component.removeClass('js-no-transition');
       }
      if($component.hasClass('js-open')) {
          closeToc($component, $trigger);
      }
      else {
          openToc($component, $trigger);
      }
   });

    $component.addClass('js-c-toc--initialized');


}
function closeToc($toc, $trigger) {
    $toc.removeClass('js-open');
    $trigger.data('aria-label', 'Navigation öffnen').data('aria-expanded', false);
}
function openToc($toc, $trigger) {
    $toc.addClass('js-open');
    $trigger.data('aria-label', 'Navigation schließen').data('aria-expanded', true);

    $b.on('click.handleTocClose', function(e){
        let $target = $(e.target);

        if($toc.find($target).length === 0) {

            closeToc($toc, $trigger);
            $b.off('click.handleTocClose');
        }
    });
}
function updateViewport() {

    let $viewport = getViewport();
    viewportWidth = $viewport.width;
    viewportHeight = $viewport.height;

}
function setTofixed() {

    $component.before("<div class='js-toc-spacer' style='height:" + $component.outerHeight() +"px'></div>");
    $component.addClass('js-fixed js-no-transition');
    let triggerBottomVal = parseInt($component.css('bottom').replace('px', '')) ;


    $nav.css('max-height', viewportHeight - headerHeight - $trigger.outerHeight(true) - (triggerBottomVal *2) + 'px');
}
function setToRelative() {
    $component.prev('.js-toc-spacer').remove();
    $component.removeClass('js-fixed');
    $nav.css('max-height', 'none');
}
function handleWindowScroll($toc) {
    let  componentOffset = $toc.offset(),
        componentHeight = $toc.outerHeight();
    if(viewportWidth <= breakpoints.tablet) {
        if($w.scrollTop() + headerHeight >= componentOffset.top + componentHeight) {
                setTofixed();
        }
        $w.on('scroll.cToc', function() {

            if($w.scrollTop() + headerHeight >= componentOffset.top + componentHeight) {
                if(!$toc.hasClass('js-fixed')) {
                    setTofixed();
                }
            }
            else {
                if($toc.hasClass('js-fixed')) {
                    setToRelative();
                }
            }
        });
    }
    else {
        $w.off('scroll.cToc');
        setToRelative();
    }
}
