import React, {Component} from 'react';
import {CSSTransition} from "react-transition-group";
import {breakpoints, transitionDurations} from "../../../javascript/config";
import {getViewport, sumUpArrayValues} from "../../../javascript/helpers";

class HeroVisual extends Component {
    constructor(props) {
        super(props);

        const viewport = getViewport();
        let isMobile = false;
        if(viewport.width <= breakpoints.tablet) {
            isMobile = true;
        }

        this.state = {
            isMobile: isMobile,
            activeSlide: 0,
            //The sliderItem responsible for the position of the slider. This is different from "activeSlide"
            //because we can move the Slider and look at the other sliderItems, whithout changing which item is
            //active (= without changing what video and caption we're looking at).
            //Ie. we currently see the first slide (= sliderItem 1 is active), but I'm moving the menu
            //far to the right and release my finger/mouse. It snaps to sliderItem 2 (because that is the nearest
            //sensible position for the slider after I stop dragging), but we're NOT changing our position on the page
            //and acnhor 1 is still the one we're looking at
            activeMenuItemIndex: 0,
            trackIsBeingScrolled: false
        }

        this.resizeTimeout = null;
        this.sliderItemRefs = [];
        for(let i=0; i < this.props.slides.length; i++) {
            this.sliderItemRefs[i] = React.createRef();
        }
        this.sliderItemWidths = [];
        this.trackWrapperRef = React.createRef();
        this.trackWrapperRefWidth = null;
        this.trackWrapperRefHeight = null;
        this.trackRef = React.createRef();
        this.trackPositionThumbnailMapping = null;
        this.componentIsMounted = false;
        this.oldPointerTouchX = null;
        this.currentPointerTouchX = null;
        this.trackPositionX = 0;
        this.menuClickEvent = new Event('_menuClick');


        this.handleMousedownTouchstart = this.handleMousedownTouchstart.bind(this);
        this.handleMouseupTouchend = this.handleMouseupTouchend.bind(this);
        this.handleMousemoveTouchmove = this.handleMousemoveTouchmove.bind(this);
        this.handleMenuClick = this.handleMenuClick.bind(this);
        this.handleResize = this.handleResize.bind(this);
        this.autoplay = this.autoplay.bind(this);
    }

    componentDidMount() {
        this.componentIsMounted = true;
        this.initializeValues();
        this.autoplay();
        window.addEventListener('resize', this.handleResize);
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.handleResize);
    }

    autoplay(){

        const _this = this,
            maxIndex = _this.sliderItemRefs.length - 1,
            autoplayInterval = new Timer(function (){

                const index = _this.state.activeSlide === maxIndex ? 0 : _this.state.activeSlide + 1;

                _this.setState({
                    activeSlide: index,
                    activeMenuItemIndex: index
                }, () => {
                    _this.moveTrack();
                });

            }, _this.props.autoplaySpeed);

        window.addEventListener('_menuClick', function (){

            autoplayInterval.reset(_this.props.autoplaySpeed);

        });

        function Timer(fn, t) {
            let timerObj = setInterval(fn, t);

            this.stop = function() {
                if (timerObj) {
                    clearInterval(timerObj);
                    timerObj = null;
                }
                return this;
            }

            // start timer using current settings (if it's not already running)
            this.start = function() {
                if (!timerObj) {
                    this.stop();
                    timerObj = setInterval(fn, t);
                }
                return this;
            }

            // start with new or original interval, stop current interval
            this.reset = function(newT = t) {
                t = newT;
                return this.stop().start();
            }
        }

    }

    /**
     * Gets the width of the trackWrapper, the width of all the items in the slider and
     * and calculates how many items fit into one wrapperWidth
     * @effect - Sets this.trackWrapperRefWidth, this.trackWrapperRefHeight
     */
    initializeValues() {
        if(this.componentIsMounted) {
            this.trackWrapperRefWidth = this.trackWrapperRef.current.getBoundingClientRect().width;
            this.trackWrapperRefHeight = this.trackWrapperRef.current.getBoundingClientRect().height;
            this.sliderItemRefs.map((item, index)=> {
                this.sliderItemWidths[index] = this.sliderItemRefs[index].current.getBoundingClientRect().width;
            });
            this.generateTrackPositionThumbnailMapping();
        }
    }

    /**
     * Creates an array that maps activeThumbnails to trackPositions.
     * Meaning: "If the active thumbnail is thumbnail no. 4, the track must be moved
     * array[4] pixels
     * @effects: sets this.trackPositionThumbnailMapping
     */
    generateTrackPositionThumbnailMapping() {
        this.trackPositionThumbnailMapping = [];
        this.props.slides.map((item, index)=> {
            this.trackPositionThumbnailMapping.push(-(sumUpArrayValues(this.sliderItemWidths, index-1)));
        });
    }

    /**
     * Handles start start of dragging with mouse or finger. Sets this.oldPointerTouchX
     * and this.currentPointerTouchX and starts eventListeners for touchMove and mouseMove if
     * the slider actually houses more items than one slider width can hold
     * @effects sets this.oldPointerTouchX and this.currentPointerTouchX
     * @param event
     */
    handleMousedownTouchstart(event) {
        if(this.state.isMobile === true) {
            event.preventDefault();
            if(event.touches) {
                this.oldPointerTouchX = event.touches[0].clientX;
                this.currentPointerTouchX = event.touches[0].clientX;
            }
            else {
                this.oldPointerTouchX = event.clientX;
                this.currentPointerTouchX = event.clientX;
            }
            //Only allow swiping if available space is less than needed for all items
            if(this.trackWrapperRefWidth < this.sliderItemWidths.reduce((a,b)=>a+b)) {
                window.addEventListener('touchmove', this.handleMousemoveTouchmove, {passive: false});
                window.addEventListener('mousemove', this.handleMousemoveTouchmove, {passive: false});
                window.addEventListener('touchend', this.handleMouseupTouchend, {passive: false});
                window.addEventListener('mouseup', this.handleMouseupTouchend, {passive: false});
            }
        }
    }

    /**
     * Calculates and sets the translateX-value on the track reference based on the initial touchstart/mousedown-coordinate
     * and the current touchmove/mousemove-coordinate.
     * The actual logic whether the track SHOULD be moved further also happens here.
     * @effects sets transistion on trackReference to none; sets this.trackPositionX and the actual translateX-value
     * on the trackReference; sets this.state.trackIsBeingScolled; prevents default
     * @param event
     */
    handleMousemoveTouchmove(event) {
        //This is important to allow dragging with mouse
        if(event.cancelable) {
            event.preventDefault();
        }

        if(!this.state.trackIsBeingScrolled) {
            this.setState({
                trackIsBeingScrolled: true
            })
        }

        this.trackRef.current.style.transition = `none`;
        this.oldPointerTouchX = this.currentPointerTouchX;
        if(event.touches) {
            this.currentPointerTouchX = event.touches[0].clientX;
        }
        else {
            this.currentPointerTouchX = event.clientX;
        }
        this.trackPositionX = this.trackPositionX - (this.oldPointerTouchX - this.currentPointerTouchX);

        if(this.trackPositionX < -(this.sliderItemWidths.reduce((a,b)=>a+b) - this.trackWrapperRefWidth)) {
            this.trackPositionX = -(this.sliderItemWidths.reduce((a,b)=>a+b) - this.trackWrapperRefWidth)
        }
        else if (this.trackPositionX > 0) {
            this.trackPositionX = 0;
        }

        this.trackRef.current.style.transform = `translateX(${this.trackPositionX}px)`;
    }

    /**
     * Handles stop of the dragging process by setting a new activeMenuItemIndex and removing all event listeners
     * @effects sets transition back to the initial value given by stylesheet; removes all eventListeners
     * sets this.state.activeMenuItemIndex and this.state.trackIsBeingScrolled and triggers this.moveTrack()
     */
    handleMouseupTouchend() {
        this.trackRef.current.style.transition = "";
        window.removeEventListener('touchmove', this.handleMousemoveTouchmove);
        window.removeEventListener('mousemove', this.handleMousemoveTouchmove);


        if(this.state.trackIsBeingScrolled) {
            let nextBestactiveMenuItemIndex;


            for(let u=0; u < this.trackPositionThumbnailMapping.length; u++) {
                if(this.trackPositionX <= this.trackPositionThumbnailMapping[u] && this.trackPositionX >= this.trackPositionThumbnailMapping[u+1]) {

                    //If slider was moved to the absolute right, in some cases it always snaps back to one thumbnail
                    //before. This is why we first ask whether this is the case - and then set the activeMenuItemIndex
                    //to the last thumbnail.
                    if(this.trackPositionX === -(this.sliderItemWidths.reduce((a,b)=>a+b) - this.trackWrapperRefWidth)) {
                        nextBestactiveMenuItemIndex = this.trackPositionThumbnailMapping.length;
                    }
                    else if(Math.abs(this.trackPositionX - this.trackPositionThumbnailMapping[u]) < Math.abs(this.trackPositionThumbnailMapping[u+1] - this.trackPositionX)) {
                        nextBestactiveMenuItemIndex = u;
                    }
                    else {
                        nextBestactiveMenuItemIndex = u + 1;
                    }
                    break;
                }
            }

            this.setState({
                activeMenuItemIndex: nextBestactiveMenuItemIndex,
                trackIsBeingScrolled: false
            }, ()=> {
                this.moveTrack()
            });
        }

        window.removeEventListener('touchend', this.handleMouseupTouchend);
        window.removeEventListener('mouseup', this.handleMouseupTouchend);
    }

    /**
     * Sets this.trackPositionX and the actual translateX-value on the track. Usually in a react context, one would
     * expect the new translateX-value to be set in render() depending on sth like this.state.trackPositionX. But
     * triggering this.setState in a touchMove-Handler is not a good idea, that's why we have to save the trackPositionX
     * in a global value outside the state and set the value on manually on the trackReference.
     */
    moveTrack() {
        if(this.state.isMobile) {
            //If available space is bigger than space needed by sliderItems
            if(this.trackWrapperRefWidth >= this.sliderItemWidths.reduce((a,b)=>a+b)) {
                this.trackPositionX = 0;
            }
            else {
                //Default case
                this.trackPositionX = -(sumUpArrayValues(this.sliderItemWidths, this.state.activeMenuItemIndex - 1));

                if(this.trackPositionX < -(this.sliderItemWidths.reduce((a,b)=>a+b) - this.trackWrapperRefWidth)) {
                    this.trackPositionX = -(this.sliderItemWidths.reduce((a,b)=>a+b) - this.trackWrapperRefWidth)
                }

                else if(this.trackPositionX > 0) {
                    this.trackPositionX = 0;
                }
            }
        }
        else {
            this.trackPositionX = 0;
        }

        this.trackRef.current.style.transform = `translateX(${this.trackPositionX}px)`;
    }

    handleResize() {
        clearTimeout(this.resizeTimeout);
        this.resizeTimeout = setTimeout(() =>{
            const viewport = getViewport();
            if(viewport.width <= breakpoints.tablet) {
                this.setState({
                    isMobile: true
                }, () => {
                    this.initializeValues();
                });
            }
            else {
                this.setState({
                    isMobile: false
                }, () => {
                    this.moveTrack();
                });
            }
        }, 300)
    }

    handleMenuClick(index) {

        window.dispatchEvent(this.menuClickEvent);

        if(!this.state.trackIsBeingScrolled) {
            this.setState({
                activeSlide: index,
                activeMenuItemIndex: index
            }, () => {
                this.moveTrack();
            });
        }

    }

    renderMenu() {
        return this.props.slides.map((slide, index)=>{
            let activeClass="";
            if(this.state.activeSlide === index) {
                activeClass = "m-hero-visual__menu-item--active"
            }
            return(
                <li ref={this.sliderItemRefs[index]} key={index} className={`m-hero-visual__menu-item ${activeClass}`}>
                    <span onClick={()=>{this.handleMenuClick(index)}}>{slide.title}</span>
                </li>
            )
        });
    }

    renderDots() {
        return this.props.slides.map((slide, index)=>{
            let activeClass="";
            if(this.state.activeSlide === index) {
                activeClass = "m-hero-visual__dots-item--active"
            }
            return(
                <li key={index} className={`m-hero-visual__dots-item ${activeClass}`}>
                    <button className="button" onClick={()=>{this.handleMenuClick(index)}} title={slide.title} aria-label={slide.title}/>
                </li>
            )
        });
    }

    renderVisual() {
        return this.props.slides.map((slide, index)=>{
            if(slide.videoUrl) {
                return(
                    <CSSTransition key={index} in={this.state.activeSlide === index} timeout={transitionDurations.medium} classNames="m-hero-visual__visual-">
                        <video className="m-hero-visual__video" autoPlay muted playsInline loop>
                            <source src={slide.videoUrl} type="video/mp4"/>
                        </video>
                    </CSSTransition>
                )
            }
            else if(slide.image) {
                return(
                    <CSSTransition key={index} in={this.state.activeSlide === index} timeout={transitionDurations.medium} classNames="m-hero-visual__visual-">
                        <div className="m-hero-visual__visual" >
                            <figure className="c-figure">
                                <div className="c-figure__inner">
                                    <picture>
                                        <source srcSet={slide.image.srcsetUrlMobile} media="(max-width: 520px)"/>
                                        <source srcSet={slide.image.srcsetUrlTablet} media="(max-width: 980px)"/>
                                        <source srcSet={slide.image.srcsetUrlNetbook} media="(max-width: 1360px)"/>
                                        <source srcSet={slide.image.srcsetUrlLaptop } media="(max-width: 1580px)"/>
                                        <source srcSet={slide.image.srcsetUrlDesktop } media="(max-width: 1921px)"/>
                                        <source srcSet={slide.image.srcsetUrlCinema} media="(min-width: 1922px)"/>
                                        <img src={slide.image.url} alt={slide.image.alt}/>
                                    </picture>

                                    <div className="c-figure__copyright">&copy; {slide.image.copyright}</div>

                                </div>
                            </figure>


                        </div>
                    </CSSTransition>
                )
            }
        });
    }

    renderCaption() {
        return this.props.slides.map((slide, index)=>{
            return(
                <CSSTransition key={index} in={this.state.activeSlide === index} timeout={transitionDurations.medium} classNames="m-hero-visual__caption-">
                    <div className="m-hero-visual__caption">
                        <div className="m-hero-visual__caption-inner">
                            <h2>{slide.title}</h2>
                            <p>{slide.caption}</p>
                        </div>
                        <a href={slide.href} className="m-hero-visual__caption-button"><span>Mehr erfahren</span></a>
                    </div>
                </CSSTransition>
            )
        });
    }

    render() {
        return (
            <React.Fragment>
                <div ref={this.trackWrapperRef} className="m-hero-visual__menu">
                    <ul
                        ref={this.trackRef}
                        className="m-hero-visual__menu-inner"
                        onTouchStart={this.handleMousedownTouchstart}
                        onMouseDown={this.handleMousedownTouchstart}
                    >
                        {this.renderMenu()}
                    </ul>
                </div>
                <div className="m-hero-visual__visual-container">
                    {this.renderVisual()}
                </div>
                <div className="m-hero-visual__caption-container">
                    {this.renderCaption()}
                </div>
                <div className="m-hero-visual__dots">
                    <ul className="m-hero-visual__dots-inner">
                        {this.renderDots()}
                    </ul>
                </div>
            </React.Fragment>
        );
    }
}

export default HeroVisual;
