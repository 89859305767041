const $modules = $('.js-quicksearch');

$modules.each(function() {
    const $this = $(this);
    const $tabs = $this.find('.js-tab');
    const $contents = $this.find('.js-content');

    $tabs.on('click', function() {
        const $this = $(this);
        const index = $tabs.index($this);

        handleTabClick(index);
    })

    function handleTabClick(index) {
        $tabs.removeClass('js-active');
        $tabs.eq(index).addClass('js-active');
        $contents.removeClass('js-visible');
        $contents.eq(index).addClass('js-visible');
    }
});