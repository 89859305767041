import React from 'react';
import PropTypes from "prop-types";
import ReactEventMapItem from "./ReactEventMapItem";
import ReactEventMapMap from "./ReactEventMapMap";


import {
    generatedFilterObject,
    handleFilterChange,
    handleFilterSelectChange,
    runFilter,
    calcCrow,
    getLatLngByZip,
    checkSessionForActiveFilters,
    checkFilterSetupForActiveFilters,
    generateSessionName,
    sortItems,
    reduceDataForSameLocations
} from "../../../components/react-filter/react/ReactFilterHelpers";
import ReactPagination from "../../../components/react-pagination/ReactPagination";
import ReactFilter from "../../../components/react-filter/react/ReactFilter";
import dateFns from "date-fns";
const deLocale = require('date-fns/locale/de');

export default class ReactEventMap extends React.Component {

    constructor(props) {
        super(props);
        this.showMapSessionName = "dvnlp-showMap"; //@ToDoFe - remove all aok references, use sessionname from data
        this.pagebrowserSessionName = "dvnlp-react-event-pagebrowserPageIndex";
        this.allowGoogleSessionName = "dvnlp-allow-google";
        this.moduleName = "react-event-map";
        this.data = this.props.data;
        this.filterableItems = this.data.events;
        this.filteredItems = this.filterableItems;
        this.itemsToShow = this.filteredItems;
        this.showMapDefault = this.data.showMap;
        this.pagebrowserDefault = 0;
        this.initialOptInState = this.checkCookiesForOptIn();
        this.breakpointTablet = 912;
        this.filterObject = generatedFilterObject(this.data.filterSetup);
        this.setupActiveFilters = checkFilterSetupForActiveFilters(this.props.data.filterSetup.items);
        //if filter settings are to be remembered we need to check for existing settings in session before rendering
        if(this.data.filterSetup.rememberFilter && this.data.filterSetup.rememberFilter.enabled) {
            //generate sessionName and save it globally so that the filterHelpers can access it
            this.rememberFilter = true;
            this.sessionName = generateSessionName(this.moduleName, this.props.moduleIndex, this.data.filterSetup.rememberFilter.sessionName);
            //checks for session item with the name
            this.checkSessionForActiveFilters = checkSessionForActiveFilters(this.sessionName);
        }

        //check if showMap preferences were saved to session
        if(sessionStorage.getItem(this.showMapSessionName)) {
            this.showMapDefault = JSON.parse(sessionStorage.getItem(this.showMapSessionName));
        }
        //check if active pagebrowser page was saved to session
        if(sessionStorage.getItem(this.pagebrowserSessionName)) {
            this.pagebrowserDefault = JSON.parse(sessionStorage.getItem(this.pagebrowserSessionName));
        }

        this.state = {
            mapOptIn: this.initialOptInState,
            viewOptOut: false,
            activePage: this.pagebrowserDefault,
            activeItem: null,
            showMap: this.showMapDefault,
            viewportHeight: 0,
            viewportWidth: 0,
            activeFilters: this.setupActiveFilters.length > 0 ? this.setupActiveFilters : this.checkSessionForActiveFilters ? this.checkSessionForActiveFilters : null
        };

        //bind functions from the FilterHelpers so they can access and change the state of this parent component
        this.handleFilterChange = handleFilterChange.bind(this);
        this.handleFilterSelectChange = handleFilterSelectChange.bind(this);
        this.calcCrow = calcCrow.bind(this);
        this.getLatLngByZip = getLatLngByZip.bind(this);


        //bind functions from this parent component
        this.handleMarkerClick = this.handleMarkerClick.bind(this);
        this.renderListItems = this.renderListItems.bind(this);
        this.renderMap = this.renderMap.bind(this);
        this.renderViewSwitchWrapper = this.renderViewSwitchWrapper.bind(this);
        this.renderResultRange = this.renderResultRange.bind(this);

        this.handleSwitchView = this.handleSwitchView.bind(this);
        this.handlePagebrowserClick = this.handlePagebrowserClick.bind(this);
        this.renderPagebrowser = this.renderPagebrowser.bind(this);
        this.resetActiveMarker = this.resetActiveMarker.bind(this);
        this.checkActivePageOutsideRange = this.checkActivePageOutsideRange.bind(this);
        this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
        this.handleViewOptOutInfo = this.handleViewOptOutInfo.bind(this);
        this.renderOptOutInfo = this.renderOptOutInfo.bind(this);
        this.changeStateAfterFiltering = this.changeStateAfterFiltering.bind(this);
        this.checkCookiesForOptIn = this.checkCookiesForOptIn.bind(this);
        this.renderDate = this.renderDate.bind(this);
        this.renderAddress = this.renderAddress.bind(this);

        //create Refs
        this.itemWrapperRef = React.createRef();

    }

    componentDidMount() {
        this.updateWindowDimensions();
        window.addEventListener('resize', this.updateWindowDimensions);
        window.addEventListener('resize', this.checkActivePageOutsideRange);
        document.addEventListener('onCookieSettingsChanged', this.checkCookiesForOptIn);
    }
    componentWillUnmount() {
        window.removeEventListener('resize', this.updateWindowDimensions);
        window.removeEventListener('resize', this.checkActivePageOutsideRange);
        document.removeEventListener('onCookieSettingsChanged', this.checkCookiesForOptIn);
    }

    checkActivePageOutsideRange() {
        if(this.itemsToShow.length === 0 && this.state.activePage > 0) {
            this.setState({
                activePage : this.state.activePage - 1
            });
        }
    }

    /**
     * check viewport and save to state
     * @effect - sets state viewportWidth & viewportHeight to current values
     */
    updateWindowDimensions() {
        this.setState({
            viewportWidth: window.innerWidth,
            viewportHeight: window.innerHeight
        });
    }


    /**
     * @effect - sets state mapOptIn to CookieSettingsManager category status if called again after init
     * @returns {boolean}
     */
    checkCookiesForOptIn() {

        const cookieStatus = window.CookieSettingsManager.getStatusForCategory('comfort');

        //update opt-in state when cookieStatus is changed
        if(this.state && (this.state.mapOptIn !== cookieStatus)) {

            this.setState({
                mapOptIn: cookieStatus
            });

        }

        return cookieStatus;
    }

    /**
     * a function that can be passed to the filter component and will be run every time the filter changes
     * @effect - sets State activePage to 0 and activeItem to null
     */
    changeStateAfterFiltering() {
        this.setState({
            activePage: 0,
            activeItem: null
        });
    }


    /**
     * sets activeItem state to null
     * @effect - sets state activeItem to null
     */
    resetActiveMarker() {
        this.setState({
            activeItem: null
        });
    }

    /**
     *
     * @param markerData = object, which needs to have property "id" of type int
     * @effect sets state activeItem to id of passed data
     */
    handleMarkerClick(markerData) {
        if(markerData.events) {

            this.setState({
                activeItem: markerData.events[0].id
            });

        }

        else {

            this.setState({
                activeItem: markerData.id
            });

        }
    }

    /**
     * handles click on optout / option buttons
     * @effect sets state viewOptOut depending on current state viewOptOut to opposite (bool)
     */
    handleViewOptOutInfo() {

        this.setState({
            viewOptOut: !this.state.viewOptOut
        });

    }

    /**
     *
     * @param newActivePage = type number, index of next active page
     * @effect - scrolls to list, new active page to session, sets state activePage to new active page (index)
     */
    handlePagebrowserClick(newActivePage) {
        //scroll to top of list on page-switch
       // $(this.itemWrapperRef.current).phScrollTo(-40, 600); //@ToDoFe - enable scroll to top of list on pagination click
        sessionStorage.setItem(this.pagebrowserSessionName, newActivePage);

        this.setState({
            activePage: newActivePage
        });
    }

    /**
     * handles click on switchView button
     * @effect - saves opposite of current state showMap (bool) to session & state showMap
     */
    handleSwitchView() {

        sessionStorage.setItem(this.showMapSessionName, JSON.stringify(!this.state.showMap ));

        this.setState({
            showMap : !this.state.showMap
        });

    }

    /**
     * renders view switch when result contains non online events
     * @returns {*}
     */
    renderViewSwitchWrapper() {

        const switchButtonClass = this.state.showMap ? '' : 'ph-switch-button--active',
            switchButtonDisabledClass = this.filteredItems.length > 0 ? '' : 'ph-switch-button--disabled';

        if(this.filteredItems.length > 0) {
            return(

                <div className="m-react-event-map__view-switch-wrapper" >
                    <button type="button" disabled={this.filteredItems.length <= 0} className={"m-react-event-map__view-switch ph-switch-button " + switchButtonClass + ' ' + switchButtonDisabledClass} onClick={()=>this.handleSwitchView()}>
                        <span className="ph-switch-button-wrapper">
                            <span className="ph-switch-button__label">Karte ausblenden</span>
                            <span className="ph-switch-button__button"/>
                            <span className="ph-switch-button__label ph-switch-button__label--after">Karte einblenden</span>
                        </span>
                    </button>
                </div>
            )
        }

    }

    /**
     * renders result range when result has items
     * @returns {*}
     */
    renderResultRange(firstItemToShowNumber, lastItemToShowNumber) {

        if(this.filteredItems.length > 0) {

            return (
                <div className="m-react-event-map__result-range">
                    {firstItemToShowNumber + lastItemToShowNumber + " von " + this.filteredItems.length + " Angeboten"}
                </div>
            )

        }

    }

    /**
     * renders map area depending on mapOptIn state
     * @returns {*}
     */
    renderMap() {

        if(this.state.showMap === true) {

            if (!this.state.mapOptIn){

                return(
                    <React.Fragment>
                        <div className="m-react-event-map__optin">
                            <div className="m-react-event-map__map-image" style={{backgroundImage:"url("+ this.data.optIn.false.image +")"}}/>
                            <div className="m-react-event-map__optin-text-wrapper">
                                <p className="m-react-event-map__optin-text" dangerouslySetInnerHTML={{__html: this.data.optIn.false.text}}/>
                                <button className="m-react-event-map__button button button--cta" data-cookie-settings-manager="trigger"><span>{this.data.optIn.false.buttonText}</span></button>
                            </div>

                        </div>
                    </React.Fragment>
                )

            }

            else {

                if(this.filteredItems.length > 0) {
                    //get events that take place on location instead of online and reduce items with same location
                    let onLocationEvents = reduceDataForSameLocations(this.filteredItems.filter((item) => item.lat && item.lng), 'events');

                    //@ToDoFe - enable excludeFromAreaSearch: true for online-events or general syntax for exceptions

                    if(onLocationEvents.length > 0) {
                        return(
                            <div className="m-react-event-map__map-wrapper" >
                                <ReactEventMapMap
                                    markerData = {onLocationEvents}
                                    clusterMarkerPath = {this.data.clusterMarkerPath}
                                    handleMarkerClick = {this.handleMarkerClick}
                                    resetActiveMarker = {this.resetActiveMarker}
                                    apiKey = {this.props.apiKey}
                                    activeMarkerId = {this.state.activeItem}
                                    renderDateFunc = {this.renderDate}
                                    renderAddressFunc = {this.renderAddress}
                                />
                                <div className="m-react-event-map__optout">
                                    {this.renderOptOutInfo()}
                                </div>
                            </div>

                        )
                    }
                    else {
                        return(
                            <React.Fragment>
                                <div className="m-react-event-map__optin">
                                    <div className="m-react-event-map__map-image" style={{backgroundImage:"url("+ this.data.optIn.false.image +")"}}/>
                                    <div className="m-react-event-map__optin-text-wrapper">
                                        <p className="m-react-event-map__optin-text" >
                                            Die Ergebnisse können nicht auf der Karte angezeigt werden.
                                        </p>
                                    </div>
                                </div>
                            </React.Fragment>
                        )
                    }

                }

            }
        }

    }

    renderDate(dateObject) {
        let dayAndMonthStart = dateFns.format(dateObject.startDate, 'DD.MM.', {locale: deLocale}),
            yearStart = dateFns.format(dateObject.startDate, 'YYYY', {locale: deLocale});

        //multiple days event
        if(dateObject.endDate) {
            let dayAndMonthEnd =  dateFns.format(dateObject.endDate, 'DD.MM.', {locale: deLocale}),
                yearEnd = dateFns.format(dateObject.endDate, 'YYYY', {locale: deLocale});
            // within same year
            if(yearStart === yearEnd) {
                return(
                    <React.Fragment>
                        <div className="m-react-event-map__item-date-day-month">
                            {dayAndMonthStart} - <br/>
                            {dayAndMonthEnd}
                        </div>
                        <div className="m-react-event-map__item-date-year">
                            {yearStart}
                        </div>
                    </React.Fragment>
                )
            }
            else {
                return(
                    <React.Fragment>
                        <div className="m-react-event-map__item-date-day-month">
                            {dayAndMonthStart}
                        </div>
                        <div className="m-react-event-map__item-date-year">
                            {yearStart} -
                        </div>
                        <div className="m-react-event-map__item-date-day-month">
                            {dayAndMonthEnd}
                        </div>
                        <div className="m-react-event-map__item-date-year">
                            {yearEnd}
                        </div>
                    </React.Fragment>
                )
            }
        }
        else {
            //regular single day event
            return(
                <React.Fragment>
                    <div className="m-react-event-map__item-date-day-month">
                        {dayAndMonthStart}
                    </div>
                    <div className="m-react-event-map__item-date-year">
                        {yearStart}
                    </div>
                </React.Fragment>
            )
        }
    }

    renderAddress(item) {
        if(item.online) {
            return "online"
        }
        else {
            let addressName = item.addressName ? <React.Fragment>{item.addressName} <br/></React.Fragment> : '',
                street = item.street ? <React.Fragment>{item.street} <br/></React.Fragment> : '',
                zipCity = item.zip && item.city ? item.zip+' '+item.city : '';
            return(
                <React.Fragment>
                    {addressName}
                    {street}
                    {zipCity}
                </React.Fragment>
            )
        }
    }

    /**
     * renders optOutInfo depending on viewOptOut state
     * @returns {*}
     */
    renderOptOutInfo() {
        return(
            <button type="button" className="button button--cta" data-cookie-settings-manager="trigger"><span>{this.data.optIn.true.buttonText}</span></button>
        )
}

    /**
     * renders list items
     * @returns {*}
     * @effect - returns either markup for all items or no-result-message depending on itemsToShow.length
     * @effect - depending on active filters the distance of the item to filtered zip will be displayed
     */
    renderListItems(){

        if(this.itemsToShow.length > 0) {
            let showDistance = false,
                selectedZip = null,
                selectedZipCoordinates = null,
                distance = null;

            //check if there are active filters
            if(this.state.activeFilters) {
                //check if one of them has property "activeRadius: true"
                for(let s = 0; s < this.state.activeFilters.length; s++) {
                    if(this.state.activeFilters[s].activeRadius === true && this.state.activeFilters[s].areaValue) {
                        selectedZip = this.state.activeFilters[s].areaValue;
                        selectedZipCoordinates = this.getLatLngByZip(selectedZip, this.props.plzData);
                        showDistance = true;
                        break;
                    }
                }
            }

            return (
                this.itemsToShow.map((item, index) => {
                    let isActive = item.id === this.state.activeItem; //check if item is active


                    if(showDistance) {
                        let currentItemCoordinates = this.getLatLngByZip(item.zip, this.props.plzData);
                        distance = Math.round(this.calcCrow(currentItemCoordinates, selectedZipCoordinates));
                        distance = distance ? distance : 0;
                    }
                    return (
                        <ReactEventMapItem
                            data={item}
                            isActive = {isActive}
                            distance = {distance}
                            selectedZip = {selectedZip}
                            key={index}
                            renderDateFunc = {this.renderDate}
                            renderAddressFunc = {this.renderAddress}
                        />
                    )
                })
            )
        }
        else {
            return (
                <div className="m-react-event-map__no-results" dangerouslySetInnerHTML={ {__html: this.data.noResultsMessage}}/>
            )
        }

    }

    /**
     *
     * @param pageAmount = int
     * @returns {*}
     * @effect - render pagination for items
     */
    renderPagebrowser(pageAmount) {
        if(pageAmount > 1) {
            return(
                <ReactPagination
                    pageAmount = {pageAmount}
                    activePage = {this.state.activePage}
                    handlePagebrowserClick = {this.handlePagebrowserClick}
                />
            )
        }
    }

    render() {
        this.visibleItems = this.state.viewportWidth >= this.breakpointTablet ? 12 : 10; //number adjusted depending on viewport due to items / row to avoid holes
        this.filteredItems = runFilter(this.state.activeFilters, this.filterableItems, this.filterObject, this.props.plzData);
        this.filteredItems = sortItems(this.filteredItems, 'up', [["date"],["startDate"]], 'de');

        let pageAmount = Math.ceil(this.filteredItems.length / this.visibleItems),
            maxItemToShowNumber = (this.state.activePage + 1) * this.visibleItems,
            minItemToShowNumber = maxItemToShowNumber - this.visibleItems + 1,
            minItemToShowIndex = minItemToShowNumber - 1,
            //if theoretical last index to be shown is bigger than actual length of results -> display result length
            lastItemToShowNumber = maxItemToShowNumber > this.filteredItems.length -1 ? this.filteredItems.length : maxItemToShowNumber,
            //if there are no results don't display minItemToShowNumber
            firstItemToShowNumber = this.filteredItems.length === 0 ? '' : minItemToShowNumber + ' - ';


    //slice before minItemToShowIndex and before maxItemToShowNumber - NOT index, as it is sliced BEFORE the given index
        this.itemsToShow = this.filteredItems.slice(minItemToShowIndex, maxItemToShowNumber);

        return(
            <div className="wrapper">
                <div className="m-react-event-map__inner">
                    <div className="m-react-event-map__content">
                        <div className="m-react-event-map__content-top">
                            <div className="m-react-event-map__filter-wrapper">
                                <ReactFilter
                                    filterData={this.data.filterSetup.items}
                                    handleFilterChange = {this.handleFilterChange}
                                    handleSelectChange = {this.handleFilterSelectChange}
                                    activeFilterStates = {this.state.activeFilters}
                                    renderLabel = {this.data.filterSetup.renderLabels}
                                    customChangeFunction = {this.changeStateAfterFiltering}
                                />
                            </div>
                            {this.renderViewSwitchWrapper()}
                            {this.renderResultRange(firstItemToShowNumber, lastItemToShowNumber)}
                        </div>
                        {this.renderMap()}
                        <div className="m-react-event-map__item-wrapper"  ref={this.itemWrapperRef}>
                            {this.renderListItems()}
                        </div>
                        {this.renderPagebrowser(pageAmount)}
                    </div>
                </div>
            </div>
        );
    }
}

ReactEventMap.propTypes = {
    data: PropTypes.shape({
        optIn: PropTypes.shape({
            false: PropTypes.shape({
                text: PropTypes.string,
                buttonText: PropTypes.string,
                image: PropTypes.string
            }),
            true: PropTypes.shape({
                text: PropTypes.string,
                buttonText: PropTypes.string
            })
        }),
        showMap: PropTypes.bool,
        filterSetup: PropTypes.shape({
            rememberFilter: PropTypes.shape({
                enable: PropTypes.bool,
                sessionName: PropTypes.string
            }),
            renderLabels : PropTypes.bool,
            items: PropTypes.arrayOf(PropTypes.oneOfType([
                PropTypes.shape({
                    type: PropTypes.string,
                    properties: PropTypes.arrayOf(PropTypes.arrayOf(PropTypes.string)),
                    inputs: PropTypes.arrayOf(PropTypes.oneOfType([
                        PropTypes.shape({
                            type: PropTypes.string,
                            id: PropTypes.string,
                            name:PropTypes.string,
                            label: PropTypes.string,
                            placeholder: PropTypes.string
                        }),
                        PropTypes.shape({
                            type: PropTypes.string,
                            multi: PropTypes.bool,
                            id: PropTypes.string,
                            label: PropTypes.string,
                            placeholder: PropTypes.string,
                            hasColors: PropTypes.bool,
                            options: PropTypes.arrayOf(PropTypes.shape({
                                    value: PropTypes.number,
                                    label: PropTypes.string
                                }
                            ))
                        })
                    ]))
                }),
                PropTypes.shape({
                    type: PropTypes.string,
                    multi: PropTypes.bool,
                    id: PropTypes.string,
                    label: PropTypes.string,
                    placeholder: PropTypes.string,
                    hasColors: PropTypes.bool,
                    options: PropTypes.arrayOf(PropTypes.shape(
                        {
                            value: PropTypes.string,
                            label: PropTypes.string,
                            color: PropTypes.string
                        }
                    )),
                    properties: PropTypes.arrayOf(PropTypes.arrayOf(PropTypes.string))
                }),
                PropTypes.shape({
                    type: PropTypes.string,
                    id: PropTypes.string,
                    name: PropTypes.string,
                    label: PropTypes.string,
                    placeholder: PropTypes.string,
                    properties: PropTypes.arrayOf(PropTypes.arrayOf(PropTypes.string))
                })
            ]))    }),
        noResultsMessage: PropTypes.string,
        events: PropTypes.arrayOf(PropTypes.shape({
                name: PropTypes.string,
                category: PropTypes.string,
                info_link: PropTypes.string,
                start_date: PropTypes.string,
                zip: PropTypes.string,
                city:  PropTypes.string,
                lat: PropTypes.number,
                lng: PropTypes.number
            }
        ))
    }),
    apiKey: PropTypes.string,
    plzData: PropTypes.arrayOf(PropTypes.shape({
        plz: PropTypes.string,
        lon:  PropTypes.number,
        lat: PropTypes.number,
        ort: PropTypes.string
    }))
};
