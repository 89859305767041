import "velocity-animate/velocity.min";

let $b,
    $component,
    $anchorLinks;

$b = $('body');
$component = $b.find('.js-c-typo3-anchor-tag:not(.js-c-typo3-anchor-tag--initialized)');
$anchorLinks = $b.find('a[href^="#"]');

if($component.length > 0) {

    $component.addClass('js-c-typo3-anchor-tag--initialized');

    $anchorLinks.on('click', function (e) {

        // set target to anchor's "href" attribute
        var scrollTargetID = $(this).attr('href'),
            $scrollTarget = $component.filter(scrollTargetID);

        //IF TARGET ON SAME PAGE
        if($scrollTarget.length >0) {

            // prevent default action and bubbling
            e.preventDefault();
            e.stopPropagation();

            // scroll to each target
            $scrollTarget.velocity('scroll', {
                duration: 800,
                easing: 'ease-in-out'
            });

        }

    });

}
