import React from 'react';
import PropTypes from "prop-types";
import 'react-dates/initialize';
import 'react-dates/lib/css/_datepicker.css';
import moment from 'moment';
import 'moment/locale/de';

import ThemedStyleSheet from 'react-with-styles/lib/ThemedStyleSheet';
import DefaultTheme from 'react-dates/lib/theme/DefaultTheme';
ThemedStyleSheet.registerTheme(DefaultTheme);

import "core-js/shim"; //for ie11 padStart polyfill
import { SingleDatePicker} from 'react-dates';

export class ReactFilterDate extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            focused: null
        };
        this.onDateChange = this.onDateChange.bind(this);
        this.onFocusChange = this.onFocusChange.bind(this);
        this.renderInput = this.renderInput.bind(this);
    }


    /**
     * @param date
     * @effect - formats value to desired generic format before triggering handleFilterChange
     **/
    onDateChange(date) {
        //check if date was selected and transform moment object to string for storing in state
        //reset time to midnight because otherwise it will return noon and the filterable items might
        //not include a time and will therefore be assigned midnight

        let startDateString = date ? date.set({'hour': 0,'minute': 0}).toDate() : null,
            endDateString = date ? date.set({'hour': 23,'minute': 59, 'second': 59}).toDate() : null,
            valueArray;
        if(date === null) {
            valueArray = null;
        }
        else {
            valueArray = [startDateString, endDateString]
        }

        this.props.handleFilterChange(this.props.filterIndex, this.props.inputID, valueArray, null, this.props.customChangeFunction ? this.props.customChangeFunction((this.props.filterIndex)) : null);
    }

    onFocusChange({focused}) {
        this.setState({
            focused
        });
    }

    renderInput() {
        let dateOutsideRange = (day) => {
            return (day.isBefore(moment(this.props.minDate)) || day.isAfter(moment(this.props.maxDate)));
        };
        let dayBlocked = (day) => {
            let isBlocked = false;
            this.props.blockedDates.map((date)=> {
                if(day.isSame(date, 'day')) {
                    isBlocked = true;
                }
            });
            return isBlocked;
        }

        return (
            <SingleDatePicker
                date={this.props.date ? moment(this.props.date) : null}
                focused = {this.state.focused}
                onDateChange = {date => this.onDateChange(date)}
                onFocusChange={focusedInput => this.onFocusChange(focusedInput)}
                initialVisibleMonth={() => moment().subtract(1, 'month')}
                isDayBlocked={dayBlocked}
                isOutsideRange={dateOutsideRange}
                showClearDate ={true}
                id={this.props.inputID}
                disabled = {this.props.disabled}
                placeholder = {this.props.placeholder}
            />
        )

    }


    render() {
        const label = this.props.renderLabel ? <label  htmlFor={this.props.inputID}>{this.props.label}</label> : '',
            formfieldClass = this.props.formfieldClass ? 'ph-formfield ' + this.props.formfieldClass : 'ph-formfield';
        return (
            <React.Fragment>
                <div className={formfieldClass}>
                    {label}
                    <div className="ph-formfield__input-wrap">
                        {this.renderInput()}
                    </div>
                </div>
            </React.Fragment>

        )
    }

}

ReactFilterDate.propTypes = {
    handleFilterChange: PropTypes.func.isRequired,
    inputID: PropTypes.string.isRequired,
    inputName: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
    date: PropTypes.instanceOf(Date),
    placeholder: PropTypes.string,
    filterIndex : PropTypes.number.isRequired,
    renderLabel : PropTypes.bool.isRequired,
    customChangeFunction: PropTypes.func,
    maxDate : PropTypes.instanceOf(Date),
    minDate: PropTypes.instanceOf(Date),
    blockedDates: PropTypes.arrayOf(PropTypes.instanceOf(Date))
};
