import React from 'react';
import PropTypes from "prop-types";
import 'react-dates/initialize';
import 'react-dates/lib/css/_datepicker.css';
import moment from 'moment';
import 'moment/locale/de';

import ThemedStyleSheet from 'react-with-styles/lib/ThemedStyleSheet';
import DefaultTheme from 'react-dates/lib/theme/DefaultTheme';
ThemedStyleSheet.registerTheme(DefaultTheme);

import "core-js/shim"; //for ie11 padStart polyfill
import { DateRangePicker} from 'react-dates';

export class ReactFilterDateRange extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            focusedInput: null
        };
        this.onDatesChange = this.onDatesChange.bind(this);
        this.onFocusChange = this.onFocusChange.bind(this);
        this.renderInput = this.renderInput.bind(this);
    }
    onFocusChange(focusedInput) {
        this.setState({ focusedInput });
    }
    /**
     * @param e
     * @effect - formats value to desired generic format before triggering handleFilterChange
     **/
    onDatesChange({ startDate, endDate }) {
        //check if start & enddate were selected and transform moment object to string for storing in state
        //reset time to midnight for startDate because otherwise it will return noon and the filterable items might not include a time an will therefore be assigned midnight
        //reset time to 23:59 for endDate to include whole day

        let startDateString = startDate ? startDate.set({'hour': 0,'minute': 0}).toDate() : null,
            endDateString = endDate ? endDate.set({'hour': 23,'minute': 59}).toDate() : null,
            valueArray;
        if(startDate === null && endDate === null) {
            valueArray = null;
        }
        else {
            valueArray = [startDateString, endDateString]
        }

        this.props.handleFilterChange(this.props.filterIndex, this.props.inputID, valueArray, null, this.props.customChangeFunction ? this.props.customChangeFunction((this.props.filterIndex)) : null);
    }




    renderInput() {
        let dateOutsideRange = (day) => {
            return (day.isBefore(moment(this.props.minDate)) || day.isAfter(moment(this.props.maxDate)));
        };

        return(
            <DateRangePicker
                startDate={this.props.startDate ? moment(this.props.startDate) : null}
                startDateId={this.props.inputID + "-start"}
                endDate={this.props.endDate ? moment(this.props.endDate) :  null}
                endDateId={this.props.inputID + "-end"}
                onDatesChange = {({ startDate, endDate }) => this.onDatesChange({ startDate, endDate })}
                focusedInput={this.state.focusedInput}
                minimumNights={0}
                showClearDates={true}
                initialVisibleMonth={() => moment().subtract(1, 'month')}
                isOutsideRange={dateOutsideRange}
                disabled = {this.props.disabled}
                onFocusChange={focusedInput => this.onFocusChange(focusedInput)}
                startDatePlaceholderText={this.props.placeholder.start}
                endDatePlaceholderText={this.props.placeholder.end}
            />
        )

    }


    render() {
        const label = this.props.renderLabel ? <label  htmlFor={this.props.inputID + "-start"}>{this.props.label}</label> : '',
            formfieldClass = this.props.formfieldClass ? 'ph-formfield ph-daterange ' + this.props.formfieldClass : 'ph-formfield ph-daterange';
        return (
            <React.Fragment>
                <div className={formfieldClass}>
                    {label}
                    <div className="ph-formfield__input-wrap">
                        {this.renderInput()}
                    </div>
                </div>
            </React.Fragment>

        )
    }

}

ReactFilterDateRange.propTypes = {
    handleFilterChange: PropTypes.func.isRequired,
    inputID: PropTypes.string.isRequired,
    value: PropTypes.string,
    inputName: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
    filterIndex : PropTypes.number.isRequired,
    renderLabel : PropTypes.bool.isRequired,
    customChangeFunction: PropTypes.func,
    startDate : PropTypes.instanceOf(Date),
    endDate : PropTypes.instanceOf(Date),
    maxDate : PropTypes.instanceOf(Date),
    minDate : PropTypes.instanceOf(Date),
    blockedDates : PropTypes.arrayOf(PropTypes.instanceOf(Date)),
    placeholder : PropTypes.shape({
        start : PropTypes.string,
        end : PropTypes.string
    })
};
