import React from 'react';
import PropTypes from "prop-types";

export class ReactFilterNumber extends React.Component {

    constructor(props) {
        super(props);
       this.handleChange = this.handleChange.bind(this);
    }

    /**
     * @param e
     * @effect - formats value to desired generic format before triggering handleFilterChange
     */
    handleChange(e) {
        //Value needs to be stored for regular filters as array so multiple values per filter are possible
        let valueArray,
            specialFilterType = this.props.isArea? "area": null;

        if(e.target.value.length === 0) {
            valueArray = null;
        }

        else {
            if(specialFilterType) {
                //special filter area & radius are handle differently and don't expect an array
                valueArray = e.target.value;
            }
            else {
                valueArray = [];
                valueArray[0] = e.target.value;
            }
        }


        this.props.handleFilterChange(this.props.filterIndex, this.props.inputID, valueArray, specialFilterType, this.props.customChangeFunction ? this.props.customChangeFunction((this.props.filterIndex)) : null);
    }

    render() {
        const label = this.props.renderLabel ? <label  htmlFor={this.props.inputID}>{this.props.label}</label> : '',
            formfieldClass = this.props.formfieldClass ? 'ph-formfield ' + this.props.formfieldClass : 'ph-formfield';
        return (
            <React.Fragment>
                <div className={formfieldClass}>
                    {label}
                    <div className="ph-formfield__input-wrap">
                        <input
                            id = {this.props.inputID}
                            name = {this.props.name}
                            type = 'number'
                            defaultValue = {this.props.value}
                            placeholder = {this.props.placeholder}
                            disabled = {this.props.disabled}
                            onChange = {(e) => this.handleChange(e)}
                        />
                    </div>
                </div>
            </React.Fragment>

        )
    }

}

ReactFilterNumber.propTypes = {
    handleFilterChange: PropTypes.func.isRequired,
    inputID: PropTypes.string.isRequired,
    value: PropTypes.string,
    inputName: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
    placeholder: PropTypes.string,
    filterIndex : PropTypes.number.isRequired,
    renderLabel : PropTypes.bool.isRequired,
    isArea : PropTypes.bool.isRequired,
    customChangeFunction: PropTypes.func
};
