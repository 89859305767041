import React from 'react';
import Select from "react-select";
import PropTypes from "prop-types";

export default class ReactFilterSelectRadius extends React.Component {
    constructor (props) {
        super(props);
        this.RadiusSelect = React.createRef();
    }
    componentDidUpdate() {
        if(this.props.isDisabled) {
            this.RadiusSelect.current.setState({
                value: null
            })
        }
    }

    render() {
        return (
            <Select
                inputId = {this.props.inputID}
                options = {this.props.options}
                placeholder = {this.props.placeholder}
                isMulti = {this.props.multiSelect}
                className = "select"
                isClearable = {this.props.isClearable}
                isSearchable = {this.props.isSearchable}
                value = {this.props.value}
                isDisabled = {this.props.isDisabled}
                classNamePrefix = "select"
                defaultValue = {this.props.defaultValue}
                onChange = {(selectedOption) => this.props.handleFilterChange(this.props.index, this.props.inputID, selectedOption ? selectedOption : null , "radius", this.props.customChangeFunction ? this.props.customChangeFunction(this.props.filterIndex) : null)}
                ref = {this.RadiusSelect}
            />
        )
    }
}


ReactFilterSelectRadius.propTypes = {
    options: PropTypes.arrayOf(PropTypes.shape(
        {
            value: PropTypes.number,
            label: PropTypes.string,
            color: PropTypes.string
        }
    )),
    placeholder: PropTypes.string,
    isMulti: PropTypes.bool,
    isClearable: PropTypes.bool,
    isDisabled: PropTypes.bool,
    value: PropTypes.number,
    defaultValue: PropTypes.shape({
        value: PropTypes.number,
        label: PropTypes.string,
    }),
    handleFilterChange: PropTypes.func,
    customChangeFunction: PropTypes.func,
    index: PropTypes.number
};
