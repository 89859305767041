import React from 'react';
import ReactDOM from 'react-dom';
import HeroVisual from "./HeroVisual";

const $modules = $('.js-hero-visual');

$modules.each(function() {
    const $this = $(this);
    const data = $this.data('slides');
    const autoplaySpeed = $this.data('autoplaySpeed');

    ReactDOM.hydrate(<HeroVisual slides={data} autoplaySpeed={autoplaySpeed}/>, $this[0]);
});
