import SlimSelect from 'slim-select';
import 'slim-select/dist/slimselect.css';

let $b = $('body'),
    $selects = $b.find('.js-select');

$selects.each(function(){
    let $select = $(this);

    new SlimSelect({
        select: '#'+$select.attr('id'),
        placeholder: $select.data('placeholder-value'),
        showSearch: $select.data('searchable') ? $select.data('searchable') : false,
        limit: $select.data('limit'),
        allowDeselect: true,
        allowDeselectOption: true,
        deselectLabel: '<span class="ss-deselect__clear-icon"></span>'
    });

});