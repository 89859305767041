import dateFns from "date-fns";

/**
 * @param filterSetupData
 * effect - generates & returns an array of objects with the properties 'index', 'properties' & 'type' (one for each filter)
 * !!!!!!!!!! needs to be called in constructor and return stored into variable of parent -->  passed to runFilter() as filterObject
 * e.g.
 * let generatedFilterStates = generatedFilterStates(filterSetupData);
 * this.state = {filterStates : generatedFilterStates}
 */
export function generatedFilterObject(filterSetupData) {
    const filterObject = {};
    if(filterSetupData && filterSetupData.items) {
        for (let i=0; i < filterSetupData.items.length; i++) {
            let currentItem = filterSetupData.items[i];

            filterObject[i] = {
                index : i,
                properties: currentItem.properties,
                type: currentItem.type,
                filterByValue: currentItem.filterByValue //filter select by value instead of by label (default)
            };
        }
    }

    return filterObject
}
export function generateSortingDefault(sortSetupData) {
    let defaultOption = null,
        defaultSetup = {};
    for(let i = 0; i < sortSetupData.options.length; i++) {
        if(sortSetupData.options[i].default === true) {
            defaultOption = sortSetupData.options[i];
            break;
        }
    }
    if(defaultOption) {
        defaultSetup = {
            value: defaultOption.value,
            property: sortSetupData.properties[defaultOption.property],
            direction: defaultOption.direction
        }
    }
    else {
        defaultSetup = {
            value: sortSetupData.options[0].value,
            property: sortSetupData.properties[sortSetupData.options[0].property],
            direction: sortSetupData.properties[sortSetupData.options[0].direction]
        }
    }
    return defaultSetup;
}

/**
 * @param moduleName
 * @param moduleIndex
 * @param sessionName
 * @returns {string}
 * @effect - returns a unique session name used as a kex for storing information in session
 */
export function generateSessionName(moduleName, moduleIndex, sessionName) {
    return sessionName + '-' + moduleName + '-' + moduleIndex;
}

/**
 *
 * @param multiple
 * @param filterIndex
 * @param newValue
 * @param regularFilterChangeFunction
 * @param customChangeFunction
 * @effect - reformats incoming value from react-select to array so that all filters (expcept areaRadius) save their results the same way, triggers the regularFilterChangeFunction with ne value
 */
export function handleFilterSelectChange(multiple, filterIndex, filterId, newValue, regularFilterChangeFunction, customChangeFunction, dependentFilterId) {
    //Value needs to be stored for regular filters as array so multiple values per filter are possible
    let valueArray;
    if(!newValue) {
        valueArray = null;
    }
    else {

        //select returns array if multiple possible
        if(multiple) {
            valueArray = newValue;
        }
        //if only single-select it doesn't' return an array but it needs to become an array for filters to work

        else {
            valueArray = [];
            valueArray[0] = newValue;
        }

    }

    regularFilterChangeFunction(filterIndex, filterId, valueArray, null, customChangeFunction ? customChangeFunction : null, dependentFilterId);
}

/**
 *
 * @param filterIndex
 * @param newValue
 * @param specialFilterType
 * @param customChangeFunction
 * @effect - builds new object or edits deepClone of state 'activeFilters' to include new active Filters and remove inactive filters before setting the resulting object as state 'activeFilters' (of parent in which this function needs to be bound)
 *
 * !!!!!!!!!!! needs to be bound in constructor of parent
 * e.g. this.handleFilterChange = handleFilterChange.bind(this);
 * !!!!!!!!!!! and passed down as prop 'handleFilterChange' to ReactFilter Component
 */
export function handleFilterChange(filterIndex, filterId, newValue, specialFilterType, customChangeFunction, dependentFilterId) {

    //deepclone existing filters state to edit or create empty object to be filled by new filters
    let tempFilterObject = this.state.activeFilters ? JSON.parse(JSON.stringify(this.state.activeFilters)) : [];

    //check is there are already active filters, if yes copy object
    if(this.state.activeFilters !== null) {

        let filterAlreadyActive = false;

        //check if filter is already active, if yes modify values saved in object
        for(let f = 0; f < tempFilterObject.length; f++) {

            if( tempFilterObject[f].index === filterIndex) { //already active filter
                filterAlreadyActive = true;

                if(newValue && newValue.length > 0) { //new value is not null
                    //handle radius & area

                    switch (specialFilterType) {
                        case "area":
                            tempFilterObject[f] = {
                                id: filterId,
                                index: filterIndex,
                                areaValue: newValue,
                                activeRadius: newValue.length === 5 //a full german zip code has 5 characters, radius should only be available if a full zip was provided
                            };
                            break;

                        case "radius":
                            tempFilterObject[f].radiusValue = newValue;
                            break;

                        //handle all other regular states
                        default:
                            tempFilterObject[f] = {
                                id: filterId,
                                index: filterIndex,
                                value: newValue,
                                dependentFilterId: dependentFilterId
                            };
                            break;
                    }

                }
                else { //new value is null
                    if(specialFilterType) {
                        if(specialFilterType === "area") {
                            //if area has no value radius shouldn't be active either, so remove this filter from activeFilters
                            tempFilterObject.splice(f, 1);
                        }
                        //is "radius"
                        else {
                            tempFilterObject[f].radiusValue = newValue;
                        }
                    }
                    else {
                        //remove this filter from activeFilters
                        tempFilterObject.splice(f, 1);

                        //remove filters with dependencies to this filter
                        if(dependentFilterId && dependentFilterId.length > 0) {

                            tempFilterObject.map((filterItem, index) => {
                                dependentFilterId.map((dependencyItem) => {
                                    if (filterItem.id === dependencyItem) {
                                        tempFilterObject.splice(index, 1)
                                    }
                                })
                            });

                        }
                    }

                }
                break;
            }
        }
        //after checking active filters for this index accept that filter wasn't used before
        if(filterAlreadyActive === false) {
            //no radius case defined because area needs to be active first so radius cannot be the initial value for this filter
            if (specialFilterType === "area") {

                tempFilterObject[tempFilterObject.length] = {
                    id: filterId,
                    index: filterIndex,
                    areaValue: newValue,
                    activeRadius: newValue.length === 5 //a full german zip code has 5 characters, radius should only be available if a full zip was provided
                };
            }
            //all other filters
            else {
                tempFilterObject[tempFilterObject.length] = {
                    id: filterId,
                    index: filterIndex,
                    value: newValue,
                    dependentFilterId: dependentFilterId
                };
            }
        }

        //sort object according to filterIndex
        tempFilterObject.sort(sortFilterObjectByIndex);
    }

    //no filters were active
    else {
        //add this filters info at position 0
        //no radius case defined because area needs to be active first so radius cannot be the initial value for this filter
        if (specialFilterType === "area") {

            tempFilterObject[0] = {
                id: filterId,
                index: filterIndex,
                areaValue: newValue,
                activeRadius: newValue.length === 5 //a full german zip code has 5 characters, radius should only be available if a full zip was provided
            };
        }
        //all other filters
        else {
            tempFilterObject[0] = {
                id: filterId,
                index: filterIndex,
                value: newValue,
                dependentFilterId: dependentFilterId
            };
        }
    }

    // if active filters are to be remembered save them to session
    if(this.rememberFilter) {
        let stringyfiedActiveFilters = JSON.stringify(tempFilterObject);

        sessionStorage.setItem(this.sessionName, stringyfiedActiveFilters);
    }

    //set new active filters in parent state
    this.setState({
        activeFilters: tempFilterObject
    });

    //trigger custom change function
    if(customChangeFunction) {
        customChangeFunction();
    }

}

export function handleFilterSortChange(newValue, properties, customChangeFunction) {
    this.setState({
       activeSorting: {
           value: newValue.value,
           property: properties[newValue.property],
           direction: newValue.direction
       }
    });

    //trigger custom change function
    if(customChangeFunction) {
        customChangeFunction();
    }

}

/**
 * @param a
 * @param b
 * @returns {number}
 * @effect - sorts items by propertie 'index'
 */
function sortFilterObjectByIndex( a, b) {
    if ( a.index < b.index ){
        return -1;
    }
    if ( a.index > b.index ){
        return 1;
    }
    return 0;
}

/**
 * @param sessionName
 * @returns {any | null}
 * @effect - checks if there is already an item with sessionname and returns value
 */
export function checkSessionForActiveFilters(sessionName) {
    let stringyfiedActiveFilters = sessionStorage.getItem(sessionName),
        activeFiltersObject = stringyfiedActiveFilters ? JSON.parse(stringyfiedActiveFilters) : null;

    return activeFiltersObject;
}

/**
 *
 * @param filterData
 * @returns {[]}
 */
export function checkUrlForActiveFilters(filterData) {

    //@ToDoFe: implement functionality for all filter types

    const queryString = window.location.search;

    let parameters = [],
        activeFilterStates = [];

    queryString.replace(/[?&]+([^=&]+)=([^&]*)/gi, function (m, key, value) {

        let obj = {
            itemKey: decodeURI(key),
            itemValue: decodeURI(value)
        }

        parameters.push(obj);

    });

    for(let i = 0; i < parameters.length; i++) {

        for(let j = 0; j < filterData.length; j++) {

            if(filterData[j].label === parameters[i].itemKey) {

                const options = filterData[j].options;

                for(let k = 0; k < options.length; k++) {

                    if(options[k].label === parameters[i].itemValue) {

                        activeFilterStates.push({
                            id: filterData[j].id,
                            index: j,
                            value: [options[k]]
                        });

                    }

                }

            }

        }

    }

    return activeFilterStates;
}

/**
 *
 * @param filterData
 * @returns {[]}
 */
export function checkFilterSetupForActiveFilters(filterData) {

    let activeFilterStates = [];

    for (let i = 0; i < filterData.length; i++) {
        let tempFilterData = {},
            preselectedValue = false;
        //presumably the same for all/most types
        if(filterData[i].dependentFilterId) {
            tempFilterData.dependentFilterId = filterData[i].dependentFilterId;
        }
        tempFilterData.id = filterData[i].id ? filterData[i].id : '';
        tempFilterData.index = i;
        //@ToDoFe: find default value solution that works for all inputs

        //for selects
        if (filterData[i].options) {

            const filteredOptions = filterData[i].options.filter(function (el) {
                return el.selected === true;
            });

            if(filteredOptions.length > 0) {
                tempFilterData.value = filteredOptions;
                preselectedValue = true;
            }

        }

        //for checkboxes
        if(filterData[i].checked) {
            tempFilterData.value = [true];
            preselectedValue = true;
        }

        //for date
        if(filterData[i].dateValue) {

            let date = dateFns.formatISO(filterData[i].dateValue);
            tempFilterData.value = [date];
            preselectedValue = true;

        }

        //for daterange
        if(filterData[i].dateValues) {

            let valueArray = [],
                startDate = dateFns.formatISO(filterData[i].dateValues.start),
                endDate = filterData[i].dateValues.end ? dateFns.formatISO(filterData[i].dateValues.end) : false;


            //start-date is required
            valueArray.push(startDate);

            if(endDate) {

                //end-date is optional
                valueArray.push(endDate);

            }

            tempFilterData.value = valueArray;
            preselectedValue = true;

        }

        //for text input
        if(filterData[i].value) {
            tempFilterData.value = [filterData[i].value];
            preselectedValue = true;
        }

        //for text number
        if(filterData[i].numberValue) {
            tempFilterData.value = [filterData[i].numberValue];
            preselectedValue = true;
        }

        //for areaRadius
        if(filterData[i].areaValue){
            let radiusValue = false;
            if(filterData[i].radiusValue) {
                radiusValue = {
                    label: filterData[i].radiusValue.label,
                    value: filterData[i].radiusValue.value
                };
            }

            tempFilterData.activeRadius = true;
            tempFilterData.id = filterData[i].inputs[0].id; //index 0 is always the plz input
            tempFilterData.areaValue = filterData[i].areaValue;
            tempFilterData.radiusValue = radiusValue;
            preselectedValue = true;
        }

        if(preselectedValue) {
            activeFilterStates.push(tempFilterData);
        }
    }

    return activeFilterStates;
}

/**
 * loads additional data with provided url in filter item
 * @param filterIndex filter index of changed filter
 */
export function loadAdditionalData(filterIndex){

    const _this = this,
        isLoaded = this.state.additionalLoadedData.find(obj => {
            return obj.index === filterIndex
        });

    if(this.props.data.filterSetup.items[filterIndex].loadsAdditionalData && typeof isLoaded === 'undefined') {

        let additionallyLoadedData;

        $.when(

            $.getJSON(_this.props.data.filterSetup.items[filterIndex].loadsAdditionalData, function(data){

                additionallyLoadedData = data;

            }).fail(function( jqxhr, textStatus, error ) {

                let err = textStatus + ", " + error;
                console.log( "Request Failed: " + err );

            })

        ).then(function() {

            const updatedStateArray = _this.state.additionalLoadedData.concat([{index: filterIndex}]);

            _this.loadedDataEntries = _this.loadedDataEntries.concat(additionallyLoadedData);
            _this.setState({
                additionalLoadedData: updatedStateArray
            });

        });
    }

}

/**
 * @param data
 * @param propertyNameArray
 * @returns {*}
 * @effect - returns property value
 */
function getPropertyValueFromData(data, propertyNameArray) {
    let propertyValue = data;
    for(let k = 0; k < propertyNameArray.length; k++) {
        propertyValue = propertyValue[propertyNameArray[k]];
    }
    return propertyValue
}

/**
 * Converts numeric degrees to radians
 * @param Value, number
 * @returns {number}
 */
function toRad(Value) {
    return Value * Math.PI / 180;
}

/**
 * This function takes in latitude and longitude of two locations and returns the distance between them as the crow flies (in km)
 * @param coords1, number
 * @param coords2, number
 * @returns {number}
 */
export function calcCrow(coords1, coords2) {
    let R = 6371, dLat = toRad(coords2.lat-coords1.lat),
        dLon = toRad(coords2.lon-coords1.lon),
        lat1 = toRad(coords1.lat),
        lat2 = toRad(coords2.lat),
        a = Math.sin(dLat/2) * Math.sin(dLat/2) + Math.sin(dLon/2) * Math.sin(dLon/2) * Math.cos(lat1) * Math.cos(lat2),
        c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1-a));
    return R * c;
}

/**
 * get lat & lon for a specific plz from plzData
 * @param zip
 * @param plzData
 * @effect - returns object with properties 'lon' & 'lat'
 */
export function getLatLngByZip(zip, plzData) {
    let latLon = {};

    //Get Geocoordinates for entered & data zip-number
    for(let z = 0; z < plzData.length; z++) {
        if(plzData[z]['plz'] === zip ) {
            latLon = {
                lon : plzData[z]['lon'],
                lat : plzData[z]['lat']
            };
        }
    }
    return latLon;
}

/**
 * @param dataItemZip
 * @param selectedZip
 * @param selectedRadius
 * @param plzData
 * @returns {boolean}
 * @effect - returns true || false depending on if dataItemZip matches selectedZip or optional selectedRadius
 */
function filterArea(dataItemZip, selectedZip, selectedRadius, plzData) {
    let dataMatchesFilter = false;

    //radius is selected
    if(selectedRadius) {

        //value will always be a number because area-input-type is number
        let selectedZipCoordinates = getLatLngByZip(selectedZip, plzData),
            dataItemZipCoordinates = getLatLngByZip(dataItemZip, plzData);

        //check if current dataItemZip is in the selected ZIP code or in the current radius

        //returns NaN if both coordinates are the same
        let _distanceFromCurrentZip = calcCrow({lat: selectedZipCoordinates.lat, lon: selectedZipCoordinates.lon }, {lat: dataItemZipCoordinates.lat, lon: dataItemZipCoordinates.lon});


        if(_distanceFromCurrentZip <= selectedRadius || dataItemZip === selectedZip) {

            dataMatchesFilter = true;
        }
    }
    else {
        if(dataItemZip.startsWith(selectedZip)) {
            dataMatchesFilter = true;
        }
    }

    //return boolean if zip matches Filter
   return dataMatchesFilter;

}

/**
 * @param activeFilters
 * @param filterableData
 * @param filterObject
 * @param plzData
 * @returns {[]|*}
 * @effect - returns array of objects that match the selected filters
 */
export function runFilter(activeFilters, filterableData, filterObject, plzData) {
    //if there are active filters
    if(activeFilters !== null) {

        let filteredData = filterableData;

        //loop through activeFilters
        for(let f = 0; f < activeFilters.length; f++) {
            let currentFilterIndex = activeFilters[f].index,
                currentFilterType = filterObject[currentFilterIndex].type,
                currentFilterProperties = filterObject[currentFilterIndex].properties,
                currentValues = activeFilters[f].value,
                tempFilteredData = [];

                // loop through items
                for(let i = 0; i < filteredData.length; i++) {
                    let currentData = filteredData[i],
                        itemMatchesFilter = false;

                    //loop through properties the filter is checking for and compare to value according to filterType(text, select, area, radius, checkbox, radio)
                    for (let p = 0; p < currentFilterProperties.length; p++) {
                        let currentPropertyArray = currentFilterProperties[p],
                            //create object reference from array of strings which describe the path to the property in the filter object
                            dataValueOfProperty = getPropertyValueFromData(currentData, currentPropertyArray);

                        //is no special filter
                        if(currentValues) {
                            //loop through values saved in state for current filter and compare them to property value of currentData
                            for(let v = 0; v < currentValues.length; v++) {
                                if(dataValueOfProperty) { // if value was found
                                    switch (currentFilterType) {
                                    case 'text':

                                        let lowerCaseCurrentValue = escapeRegExp(currentValues[v].toLowerCase()),
                                            lowerCasePropertyValue = dataValueOfProperty.toLowerCase();

                                        if(lowerCasePropertyValue.search(lowerCaseCurrentValue) !== -1) {
                                            itemMatchesFilter = true;
                                        }

                                        break;
                                    case 'select':
                                        let selectPropertyValue = filterObject[currentFilterIndex].filterByValue ?  currentValues[v].value : currentValues[v].label;

                                        for(let d = 0; d < dataValueOfProperty.length; d++) {
                                            if(selectPropertyValue === dataValueOfProperty[d]) {
                                                itemMatchesFilter = true;
                                            }
                                        }

                                        break;

                                    case 'radio':
                                        break;

                                    case 'checkbox':

                                        if(currentValues[v] === dataValueOfProperty) {
                                            itemMatchesFilter = true;
                                        }

                                        else if(currentValues[v] === false) {
                                            itemMatchesFilter = true;
                                        }

                                        break;


                                    case 'date':
                                        //uses same function as 'dateRange' because two values (midnight and right before midnight) were saved
                                        //to ensure items with any timestamp within that date would match the filter

                                    case 'dateRange':
                                        //dateRange always has two values (start & enddate), which is why the filter only needs to run once (processing both values in one run)
                                        //to check if an item is in between the two dates or after startdate in case of enddate = null
                                        if(v === 0) {
                                            let itemStartDateString = "",
                                                itemEndDateString = "";
                                            // string or object with one or two properties
                                            //string should be handled the same as object with one property --> only startdate
                                            if(typeof dataValueOfProperty === 'string') {
                                                itemStartDateString = dataValueOfProperty;
                                            }
                                            // is object
                                            else {
                                                let objectKeys = Object.keys(dataValueOfProperty);
                                                //first property is always startDate
                                                itemStartDateString = dataValueOfProperty[objectKeys[0]];

                                                //second property (if it exists) is always endDate
                                                if(objectKeys.length > 1 ) {
                                                    itemEndDateString = dataValueOfProperty[objectKeys[1]]
                                                }
                                            }


                                            let dateFilterStart = new Date(currentValues[0]),
                                                dateFilterEnd = currentValues[1] ? new Date(currentValues[1]) : null,
                                                dateItemStart = new Date(itemStartDateString),
                                                dateItemEnd = itemEndDateString ? new Date(itemEndDateString) : null;

                                                //NO enddate was set
                                                if(!dateFilterEnd) {
                                                    itemMatchesFilter = dateFns.isAfter(dateItemStart, dateFilterStart) || dateFns.isEqual(dateItemStart, dateFilterStart);
                                                }
                                                //enddate WAS set
                                                else {
                                                    let eventStartIsSameOrAfterFilterStart = dateFns.isEqual(dateItemStart, dateFilterStart) || dateFns.isAfter(dateItemStart, dateFilterStart),
                                                        eventStartIsSameOrBeforeFilterEnd = dateFns.isBefore(dateItemStart, dateFilterEnd) || dateFns.isEqual(dateItemStart, dateFilterEnd),
                                                        eventEndIsSameOrBeforeFilterEnd = dateFns.isBefore(dateItemEnd, dateFilterEnd) || dateFns.isEqual(dateItemEnd, dateFilterEnd);
                                                    if(dateFilterStart) {
                                                        //if item only has startdate
                                                        if(!dateItemEnd) {
                                                            itemMatchesFilter = eventStartIsSameOrAfterFilterStart && eventStartIsSameOrBeforeFilterEnd;
                                                        }
                                                        //if item has start & endDate
                                                        else {
                                                            //itemMatchesFilter = dateItemStart.isSameOrAfter(dateFilterStart) && dateItemStart.isSameOrBefore(dateFilterEnd) && dateItemEnd.isSameOrBefore(dateFilterEnd);
                                                            itemMatchesFilter = eventStartIsSameOrAfterFilterStart && eventEndIsSameOrBeforeFilterEnd;
                                                        }
                                                    }
                                                    //no startDate WAS set
                                                    else {
                                                        itemMatchesFilter = eventStartIsSameOrBeforeFilterEnd;
                                                    }
                                                }
                                        }
                                        break;

                                    }
                                }
                                //if item doesn't have a real value for the property but is 'null' --> still show the item
                                else if (dataValueOfProperty === null){
                                    itemMatchesFilter = true;
                                }
                            }
                        }
                        //is areaRadius
                        else {
                                let currentDataPlz = getPropertyValueFromData(currentData, currentPropertyArray);
                                if(currentDataPlz) {
                                    let dataMatchesFilter = filterArea(currentDataPlz, activeFilters[f].areaValue, activeFilters[f].radiusValue ? activeFilters[f].radiusValue.value : null, plzData);

                                    if(dataMatchesFilter) {
                                        itemMatchesFilter = true;
                                    }
                                }
                                //if item doesn't have a real value for pl but is 'null' (e.g. it's online) - still show the item
                                else if (currentDataPlz === null){
                                    itemMatchesFilter = true;
                                }

                            }
                        }

                    if( itemMatchesFilter) {
                        tempFilteredData.push(currentData);
                    }

                }

            filteredData = tempFilteredData;
        }

        return filteredData
    }
    //if no filter active return all original items
    else {
        return filterableData
    }

}

export function runSort(sortableData, sortStateObject, language) {

    return sortItems(sortableData, sortStateObject.direction, sortStateObject.property, language);
}

function escapeRegExp(string) {
    return string.replace(/[.*+?^${}()|[\]\\]/g, '\\$&'); // $& means the whole matched string
}

export function sortItems(items, direction, property, language) {

    let sortedItems = items.sort((a, b) => {
        let propertyA = getPropertyValueFromData(a, property),
            propertyB = getPropertyValueFromData(b, property),
            itemA =  propertyA ? propertyA.toLowerCase() : null,
            itemB = propertyB ? propertyB.toLowerCase() : null;
        if(itemA && itemB) {
            switch (direction) {
                case "up":
                    return itemA.localeCompare(itemB, language ? language : 'de');

                case "down":

                    return itemB.localeCompare(itemA, language ? language : 'de');

            }
        }
        else { //if item doesn't have the property it is sorted to the back
            if(itemA && !itemB) {
                return -1
            }
            else if(!itemA && itemB) {
                return 1
            }
            else {
                return 0
            }
        }


    });
    return sortedItems;

}

export function getMinAndMaxDateFromItems(items, datePropertyNameArray) {
    //@ToDoFe - make sure this still works since it was switched from moment.js to date-fns

    let minAndMaxDate = {
        minDate: null,
        maxDate: null,
        blockedDates: null
    };

    items.map((item)=> {

        let date = getPropertyValueFromData(item, datePropertyNameArray);

        //if none of them have a value yet, set date of item for both
        if(minAndMaxDate.minDate === null && minAndMaxDate.maxDate === null ) {
            minAndMaxDate.minDate = date;
            minAndMaxDate.maxDate = date;
        }

        //if there are already values, compare them with current date and replace them if new date is older / newer
        else {

            if(date > minAndMaxDate.maxDate) {
                minAndMaxDate.maxDate = date;
            }

            else if(date < minAndMaxDate.minDate) {
                minAndMaxDate.minDate = date;
            }

        }

    });

    //make sure dates start at midnight and end right before midnight
    minAndMaxDate = {
        minDate: dateFns.set(new Date(minAndMaxDate.minDate),({'hours': 0,'minutes': 0})),
        maxDate: dateFns.set(new Date(minAndMaxDate.maxDate),{'hours': 23,'minutes': 59, 'seconds': 59})
    }

    return minAndMaxDate;
}

export function getBlockedDateFromItems(items, datePropertyNameArray) {
    //@ToDoFe
    // - figure out why this block is commented out at what's wrong with it
    // - There was a problem with it trying to block all past dates since 1970
    // - maybe "allowedDates" would be a better way to handle it?


    /*const blockedDates = null;

    return blockedDates;

    let minAndMaxDate = {
        minDate: null,
        maxDate: null,
        blockedDates: null
    };
    items.map((item)=> {
        let date = getPropertyValueFromData(item, datePropertyNameArray);
        //if none of them have a value yet, set date of item for both
        if(minAndMaxDate.minDate === null && minAndMaxDate.maxDate === null ) {
            minAndMaxDate.minDate = date;
            minAndMaxDate.maxDate = date;
        }
        //if there are already values, compare them with current date and replace them if new date is older / newer
        else {
            if(date > minAndMaxDate.maxDate) {
                minAndMaxDate.maxDate = date;
            }
            else if(date < minAndMaxDate.minDate) {
                minAndMaxDate.minDate = date;
            }
        }
    });

    //get array of dates between minDate & maxDate
    let days = [],
        day = new Date(minAndMaxDate.minDate),
        blockedDays = [];

    while (day <= new Date(minAndMaxDate.maxDate)) {
        days.push(day.toDate());
        day = day.clone().add(1, 'd');
    }

    //now compare that array with the array of items to figure out which dates don't have results in order to block them in dateRange / date inputs
    days.map((day)=>{
        let isBlocked= false;

        //console.log('in days map');

        for(let i = 0; i < items.length; i ++) {
            if(dateFns.isEqual(getPropertyValueFromData(items[i], datePropertyNameArray),day)) {
                console.log(getPropertyValueFromData(items[i], datePropertyNameArray));
                console.log(day);
                return;
            }
            else {
                isBlocked = true;
            }
        }
        if(isBlocked) {
            blockedDays.push(day);
        }

    });

    //make sure dates start at midnight and end right before midnight
    minAndMaxDate = {
        minDate: dateFns.set(new Date(minAndMaxDate.minDate),{'hours': 0,'minutes': 0}),
        maxDate: dateFns.set(new Date(minAndMaxDate.maxDate),{'hours': 23,'minutes': 59, 'seconds': 59}),
        blockedDates: blockedDays.length > 0 ? blockedDays : null
    }

    return minAndMaxDate;*/
}

/**
 *
 * @param data //array of data where each item needs to have lat, lng & id
 * @param itemsKey // optional name of the property which will contain the array of "items for same location. default is "items"
 * @returns {*[]} // array of objects (one per location) with possible multiple "items"
 */
export function reduceDataForSameLocations(data, itemsKey) {
    return [...(data).reduce((r, o) => {
        itemsKey = itemsKey ? itemsKey : "items";
        const key = o.lat + '-' + o.lng;

        let item;

        if(r.get(key)) {

            if(r.get(key)[itemsKey]) {
                let itemsArray = r.get(key)[itemsKey];
                itemsArray.push(o);

                item = {
                    lat: r.get(key).lat,
                    lng: r.get(key).lng,
                    id: r.get(key).id
                }
                item[itemsKey] = itemsArray
            }

            else {

                item = {
                    lat: r.get(key).lat,
                    lng: r.get(key).lng,
                    id: r.get(key).id
                }

                item[itemsKey] = [
                    r.get(key),
                    o
                ];

            }

        }

        else {
            item = o;
        }

        return r.set(key, item);

    }, new Map).values()];
}
