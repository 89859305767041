import React from 'react';
import {ReactFilterText} from "./ReactFilterText";
import {ReactFilterNumber} from "./ReactFilterNumber";
import {ReactFilterDate} from "./ReactFilterDate";
import {ReactFilterDateRange} from "./ReactFilterDateRange";
import {ReactFilterSort} from "./ReactFilterSort";
import ReactFilterSelectRadius from "./ReactFilterSelectRadius";
import PropTypes from "prop-types";
import {ReactFilterCheckbox} from "./ReactFilterCheckbox";
import ReactFilterSelect from "./ReactFilterSelect";
import moment from 'moment';

export default class ReactFilter extends React.Component {
    constructor(props) {
        super(props);
        this.renderFilterInputs = this.renderFilterInputs.bind(this);
    }

    /**
     * @param filterData - object
     * @param renderLabel - bool
     * @returns {*}
     * @effect - returns markup for each filter depending on type
     */
    renderFilterInputs(filterData, renderLabel) {

        if(filterData) {
            return filterData.map((filterItem, index) => {
                    let label = renderLabel ? <label  htmlFor={filterItem.id}>{filterItem.label}</label> : ' ',
                        isActive = false,
                        currentFilterState = null;

                    //check if area input already has value & enabled radius-select
                    if(this.props.activeFilterStates) {
                        for (let i = 0; i < this.props.activeFilterStates.length; i ++ ) {
                            //this index matches activeFilterIndex  hence it is already active
                            if(this.props.activeFilterStates[i].index === index) {
                                isActive = true;
                                //save active filter
                                currentFilterState = this.props.activeFilterStates[i];
                            }
                        }
                    }

                    switch (filterItem.type) {
                        case 'select':
                            let colorStyles;

                            let hasDependentParent = false,
                                dependentParentId,
                                parentDependentVal = false,
                                dependencySelectedOptions = [];

                            //map filter setup & find items with dependent parents, save dependentParentId
                            this.props.filterData.map((item) => {
                                if (item.dependentFilterId) {
                                    item.dependentFilterId.map((dependency) => {
                                        if (dependency === filterItem.id) {
                                            hasDependentParent = true;
                                            dependentParentId = filterItem.id;
                                        }
                                    });
                                }
                            })

                            if(hasDependentParent && this.props.activeFilterStates && this.props.activeFilterStates.length > 0) {

                                //find active filter item with dependentFilterId matching dependentParentId
                                let activeDependencyParent = this.props.activeFilterStates.find(function(item) {
                                    if(item.dependentFilterId) {

                                        return item.dependentFilterId.find((id) => id === dependentParentId);
                                    }
                                    else {
                                        return false;
                                    }
                                } );

                                //store item value in parentDependentVal if activeDependencyParent exists
                                if(activeDependencyParent) {

                                    parentDependentVal = activeDependencyParent.value

                                }

                                if (parentDependentVal) {

                                    //map parentDependentVal to further map options and check if they should be displayed based on current parentDependent value, push matching option to temp array
                                    parentDependentVal.map((parentDependent) => {

                                        filterItem.options.map((option) => {

                                            option.dependencyVal.map((dependencyValItem) => {

                                                if(parentDependent.value === dependencyValItem) {

                                                    //only push option if its not already in array
                                                    if(dependencySelectedOptions.indexOf(option) === -1) {

                                                        dependencySelectedOptions.push(option)

                                                    }

                                                }

                                            })

                                        })

                                    });

                                }

                            }

                           if(filterItem.hasColors) {
                               const dot = (color = '#ccc') => ({
                                   alignItems: 'center',
                                   display: 'flex',

                                   ':before': {
                                       backgroundColor: color,
                                       borderRadius: 10,
                                       content: '" "',
                                       display: 'block',
                                       marginRight: 8,
                                       marginTop: '0.5rem',
                                       height: 10,
                                       width: 10,
                                       flexShrink: 0,
                                       alignSelf: 'flex-start'
                                   },
                               });
                               //create new stylingsobject with colors for options
                               colorStyles = {
                                   option: (styles, { data}) => {
                                       let customColorDot = dot(data.color);

                                       return jQuery.extend({}, styles, customColorDot);
                                   }

                               };
                           }

                            return (
                                <ReactFilterSelect
                                    dependentFilterId={filterItem.dependentFilterId}
                                    inputID = {filterItem.id}
                                    label = {label}
                                    options = {hasDependentParent ? dependencySelectedOptions : filterItem.options}
                                    placeholder = {filterItem.placeholder}
                                    multiSelect = {filterItem.multi}
                                    disabled = {hasDependentParent ? dependencySelectedOptions.length === 0 : filterItem.disabled}
                                    value = {isActive ? (currentFilterState.value ? currentFilterState.value : '') : null}
                                    styles = {colorStyles ? colorStyles : ''}
                                    filterIndex = {index}
                                    handleFilterChange={this.props.handleFilterChange}
                                    handleSelectChange={this.props.handleSelectChange}
                                    customChangeFunction={this.props.customChangeFunction}
                                    key = {index}
                                    formfieldClass = {filterItem.formfieldClass}
                                />
                            );
                        case 'text':
                            return(
                                <ReactFilterText
                                    handleFilterChange={this.props.handleFilterChange}
                                    inputID = {filterItem.id}
                                    value = {isActive ? (currentFilterState.value ? currentFilterState.value[0] : '') : ''}
                                    inputName = {filterItem.name}
                                    label = {filterItem.label}
                                    placeholder = {filterItem.placeholder}
                                    disabled = {filterItem.disabled}
                                    key = {index}
                                    filterIndex = {index}
                                    renderLabel = {renderLabel}
                                    customChangeFunction = {this.props.customChangeFunction}
                                    formfieldClass = {filterItem.formfieldClass}
                                />
                            );
                        case 'areaRadius':
                            //MUST have one text and one select
                            let areaInputData = filterItem.inputs[0],
                                radiusInputData = filterItem.inputs[1],
                                radiusDisabled = true,
                                defaultValue = undefined,
                                radiusLabel = renderLabel ? <label  htmlFor={filterItem.inputs[1].id}>{filterItem.inputs[1].label}</label> : ' ',
                                formfieldClassRadius = radiusInputData.formfieldClass ? 'ph-formfield ' + radiusInputData.formfieldClass : 'ph-formfield';

                            if(isActive && currentFilterState && currentFilterState.activeRadius === true) {
                                radiusDisabled = false;

                                //set default value to selected option
                                if(currentFilterState.radiusValue) {
                                    defaultValue = currentFilterState.radiusValue;
                                }
                            }
                            return(
                                <React.Fragment key={index}>
                                    <ReactFilterNumber
                                        handleFilterChange={this.props.handleFilterChange}
                                        inputID = {areaInputData.id}
                                        value = {isActive ? (currentFilterState.areaValue ? currentFilterState.areaValue : '') : ''}
                                        inputName = {areaInputData.name}
                                        label = {areaInputData.label}
                                        placeholder = {areaInputData.placeholder}
                                        disabled = {areaInputData.disabled}
                                        key = {index + '0'}
                                        filterIndex = {index}
                                        renderLabel = {renderLabel}
                                        isArea = {true}
                                        customChangeFunction = {this.props.customChangeFunction}
                                        formfieldClass = {areaInputData.formfieldClass}
                                    />
                                    <div className={formfieldClassRadius}>
                                        {radiusLabel}
                                        <div className="ph-formfield__select-wrap" >
                                            <ReactFilterSelectRadius
                                                inputID = {radiusInputData.id}
                                                options = {radiusInputData.options}
                                                placeholder = {radiusInputData.placeholder}
                                                multiSelect = {false}
                                                isClearable = {true}
                                                isSearchable = {true}
                                                value = {radiusDisabled ? null : undefined}
                                                isDisabled = {radiusDisabled}
                                                defaultValue = {defaultValue}
                                                handleFilterChange = {this.props.handleFilterChange}
                                                customChangeFunction = {this.props.customChangeFunction}
                                                index = {index}
                                                key = {index + '1'}
                                                formfieldClass = {radiusInputData.formfieldClass}
                                            />
                                        </div>
                                    </div>
                                </React.Fragment>
                            );
                        case 'checkbox':
                            return(
                                <ReactFilterCheckbox
                                    handleFilterChange={this.props.handleFilterChange}
                                    checked = {filterItem.checked || isActive}
                                    inputID = {filterItem.id}
                                    inputName = {filterItem.name}
                                    label = {filterItem.label}
                                    groupLabel = {filterItem.groupLabel}
                                    disabled = {filterItem.disabled}
                                    key = {index}
                                    filterIndex = {index}
                                    renderLabel = {renderLabel}
                                    customChangeFunction = {this.props.customChangeFunction}
                                    formfieldClass = {filterItem.formfieldClass}
                                />
                            );
                        case 'dateRange':
                            let startDate = null,
                                endDate = null;
                            if(currentFilterState && currentFilterState.value) {
                                startDate = currentFilterState.value[0] ? moment(currentFilterState.value[0]).toDate() : null;
                                endDate = currentFilterState.value[1] ? moment(currentFilterState.value[1]).toDate() : null;
                            }

                            return(
                                <ReactFilterDateRange
                                    handleFilterChange={this.props.handleFilterChange}
                                    inputID = {filterItem.id}
                                    inputName = {filterItem.name}
                                    label = {filterItem.label}
                                    startDate = {startDate}
                                    endDate = {endDate}
                                    maxDate = {this.props.minAndMaxDate ? this.props.minAndMaxDate.maxDate : null}
                                    minDate = {this.props.minAndMaxDate ? this.props.minAndMaxDate.minDate : null}
                                    disabled = {filterItem.disabled}
                                    key = {index}
                                    filterIndex = {index}
                                    renderLabel = {renderLabel}
                                    placeholder = {filterItem.placeholder}
                                    customChangeFunction = {this.props.customChangeFunction}
                                    formfieldClass = {filterItem.formfieldClass}
                                />
                            );
                        case 'date':
                            let date = null;
                            if(currentFilterState && currentFilterState.value) {
                                date = moment(currentFilterState.value[0]).toDate();
                            }
                            return (
                                <ReactFilterDate
                                    handleFilterChange={this.props.handleFilterChange}
                                    inputID = {filterItem.id}
                                    inputName = {filterItem.name}
                                    label = {filterItem.label}
                                    date = {date}
                                    maxDate = {this.props.minAndMaxDate ? this.props.minAndMaxDate.maxDate : null}
                                    minDate = {this.props.minAndMaxDate ? this.props.minAndMaxDate.minDate : null}
                                    blockedDates = {this.props.blockedDates ? this.props.blockedDates : null}
                                    disabled = {filterItem.disabled}
                                    key = {index}
                                    filterIndex = {index}
                                    renderLabel = {renderLabel}
                                    placeholder = {filterItem.placeholder}
                                    customChangeFunction = {this.props.customChangeFunction}
                                    formfieldClass = {filterItem.formfieldClass}
                                />
                            )

                        default:
                            return null;
                    }
                })

        }

    }
    render() {
        return (
            <div className="c-react-filter">
                <div className="c-react-filter__content">
                    {this.renderFilterInputs(this.props.filterData, this.props.renderLabel)}
                </div>
            </div>
        )
    }
}

ReactFilter.propTypes = {
    filterData: PropTypes.arrayOf(PropTypes.oneOfType([
            PropTypes.shape({ //areaRadius
                type: PropTypes.string,
                properties: PropTypes.arrayOf(PropTypes.arrayOf(PropTypes.string)),
                inputs: PropTypes.arrayOf(PropTypes.oneOfType([
                    PropTypes.shape({
                        type: PropTypes.string,
                        id: PropTypes.string,
                        name:PropTypes.string,
                        label: PropTypes.string,
                        placeholder: PropTypes.string
                    }),
                    PropTypes.shape({
                        type: PropTypes.string,
                        multi: PropTypes.bool,
                        id: PropTypes.string,
                        label: PropTypes.string,
                        placeholder: PropTypes.string,
                        hasColors: PropTypes.bool,
                        options: PropTypes.arrayOf(PropTypes.shape({
                                value: PropTypes.number,
                                label: PropTypes.string
                            }
                        ))
                    })
                ]))
            }),
            PropTypes.shape({ //select
                type: PropTypes.string,
                multi: PropTypes.bool,
                id: PropTypes.string,
                label: PropTypes.string,
                hasColors: PropTypes.bool,
                placeholder: PropTypes.string,
                options: PropTypes.arrayOf(PropTypes.shape(
                    {
                        value: PropTypes.number,
                        label: PropTypes.string,
                        color: PropTypes.string
                    }
                )),
                properties: PropTypes.arrayOf(PropTypes.arrayOf(PropTypes.string))
            }),
            PropTypes.shape({ //text, date
                type: PropTypes.string,
                id: PropTypes.string,
                name: PropTypes.string,
                label: PropTypes.string,
                placeholder: PropTypes.string,
                properties: PropTypes.arrayOf(PropTypes.arrayOf(PropTypes.string))
            }),
            PropTypes.shape({ //dateRange
                type: PropTypes.string,
                id: PropTypes.string,
                name: PropTypes.string,
                label: PropTypes.string,
                placeholder: PropTypes.shape({
                    start: PropTypes.string,
                    end: PropTypes.string
                }),
                properties: PropTypes.arrayOf(PropTypes.arrayOf(PropTypes.string))
            }),

        ])),
    handleFilterChange: PropTypes.func,
    handleSelectChange: PropTypes.func,
    activeFilterStates: PropTypes.arrayOf(PropTypes.oneOfType([
        PropTypes.shape({
            index: PropTypes.number,
            value: PropTypes.arrayOf(PropTypes.oneOfType([
                PropTypes.shape({
                    color: PropTypes.string,
                    label: PropTypes.string,
                    value: PropTypes.string
                }),
                PropTypes.string,
                PropTypes.number
            ]))
        }),
       PropTypes.shape({
           activeRadius: PropTypes.bool,
           areaValue: PropTypes.number,
           index: PropTypes.number,
           radius: PropTypes.number
       })
    ])),
    renderLabel: PropTypes.bool,
    customChangeFunction: PropTypes.func
};
