import React from 'react';
import PropTypes from "prop-types";

export class ReactFilterCheckbox extends React.Component {

    constructor(props) {
        super(props);
       this.handleChange = this.handleChange.bind(this);
    }

    /**
     * @param e
     * @effect - formats value to desired generic format before triggering handleFilterChange
     */
    handleChange(e) {
        //Value needs to be stored for regular filters as array so multiple values per filter are possible
        let valueArray;
        if(e.target.checked === false) {
            valueArray = null;
        }

        else {
            valueArray = [];
            valueArray[0] = e.target.checked;
        }

        this.props.handleFilterChange(this.props.filterIndex, this.props.inputID, valueArray, null, this.props.customChangeFunction ? this.props.customChangeFunction((this.props.filterIndex)) : null);
    }



    render() {
        const groupLabel = this.props.renderLabel ? <legend  >{this.props.groupLabel}</legend> : '',
            formfieldClass = this.props.formfieldClass ? 'ph-checkbox-single ' + this.props.formfieldClass : 'ph-checkbox-single';

        return (
            <React.Fragment>
                <div className={formfieldClass}>
                    {groupLabel}
                    <div className="ph-checkbox">
                        <input
                            id={this.props.inputID}
                            name={this.props.inputName}
                            type='checkbox'
                            defaultChecked={this.props.checked}
                            disabled = {this.props.disabled}
                            onChange={(e) => this.handleChange(e)}
                        />
                        <label  htmlFor={this.props.inputID}>{this.props.label}</label>
                    </div>
                </div>
            </React.Fragment>

        )
    }

}

ReactFilterCheckbox.propTypes = {
    handleFilterChange: PropTypes.func.isRequired,
    inputID: PropTypes.string.isRequired,
    value: PropTypes.bool,
    inputName: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
    filterIndex : PropTypes.number.isRequired,
    renderLabel : PropTypes.bool.isRequired,
    customChangeFunction: PropTypes.func
};
