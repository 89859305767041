import React from 'react';
import PropTypes from "prop-types";


export default class ReactEventMapItem extends React.Component {

    constructor(props) {
        super(props);
        this.renderHost = this.renderHost.bind(this);
        this.renderLevel = this.renderLevel.bind(this);
        this.renderCategory = this.renderCategory.bind(this);
    }

    renderHost(){
        if(this.props.data.host) {
            return(
                <p className="m-react-event-map__item-host">
                    {this.props.data.host}
                </p>
            )
        }
    }

    renderLevel() {
        if(this.props.data.level) {
            return(
                <p className="m-react-event-map__item-level">
                    {this.props.data.level}
                </p>
            )
        }
    }

    renderCategory() {
        if(this.props.data.category) {
            return(
                <p className="m-react-event-map__item-category">
                    {this.props.data.category}
                </p>
            )
        }
    }

    render() {
        let distance = this.props.distance != null ? <p className="m-react-event-map__item-distance">{'ca. ' + this.props.distance + ' km von ' + this.props.selectedZip}</p> : null;

        return(
            <div className="m-react-event-map__item ">
                <a href={this.props.data.infoLink} className="m-react-event-map__item-link" title={'Zu: ' + this.props.data.name} aria-label={'Zu: ' + this.props.data.name} />
                <div className="m-react-event-map__item-inner">
                    <div className="m-react-event-map__item-date">
                        {this.props.renderDateFunc(this.props.data.date)}
                    </div>
                    <div className="m-react-event-map__item-text">
                        <h3 className="m-react-event-map__item-title">{this.props.data.name}</h3>
                        <div className="m-react-event-map__item-info-wrapper">
                            <div className="m-react-event-map__item-info-container">
                                {this.renderHost()}
                                <p className="m-react-event-map__item-location">
                                    {this.props.renderAddressFunc(this.props.data)}
                                </p>
                                {distance}
                            </div>
                            <div className="m-react-event-map__item-info-container">
                                {this.renderLevel()}
                                {this.renderCategory()}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

ReactEventMapItem.propTypes = {
    data: PropTypes.shape({
            name: PropTypes.string,
            category: PropTypes.string,
            infoLink: PropTypes.string,
            startDate: PropTypes.string,
            zip: PropTypes.string,
            city:  PropTypes.string,
            lat: PropTypes.number,
            lng: PropTypes.number
        }
    ),
    isActive: PropTypes.bool,
    distance: PropTypes.number,
    selectedZip: PropTypes.string
};


