import React from 'react';
import PropTypes from "prop-types";

export default class ReactPagination extends React.Component {

    constructor(props) {
        super(props);
        this.renderPageButtons = this.renderPageButtons.bind(this);
        this.renderListItem = this.renderListItem.bind(this);

    }

    /**
     * @param key - should be index, otherwise pagenumber will be off
     * @param type - one of 'page', 'dots'
     * @param active - type bool
     * @returns {*}
     * @effect - return markup for one pagination listItem depending on type & active status
     */
    renderListItem(key, type, active) {
        if(type === 'page') {
            let activeClass = active ? 'c-pagination-1__list-item--active' : '';
            return (
                <li className="c-pagination-1__list-item" key={key}>
                    <button type="button" className={"c-pagination-1__list-item-link " + activeClass} onClick={() => this.props.handlePagebrowserClick(key)} aria-label={active ? 'aktive Seite (' + (key + 1) + ')' : 'Seite ' + (key + 1) + ' anzeigen'}>{key + 1}</button>
                </li>
            )
        }
        else if (type === 'dots') {
            return (
                <li className="c-pagination-1__list-item" key={key}>
                    ...
                </li>
            )
        }
    }

    /**
     *
     * @returns {[]}
     * @effect - returns pagination markup depending on page amount & active page
     */
    renderPageButtons(){
        let itemMarkup = [];
        //if less than or exactly 5 pages render one page button per page
        if(this.props.pageAmount <= 5 ) {
            for(let i = 0; i <= this.props.pageAmount - 1; i++) {
                let active = this.props.activePage === i;

                itemMarkup.push(
                    this.renderListItem(i, 'page', active )
                );

            }
        }

        //if more than 5 pages and one of the first 3 is active render 1 - 2 - 3 - ... - last page
        else if(this.props.pageAmount > 5 && this.props.activePage <= 2) {
            for(let i = 0; i <= 2; i++) {
                let active = this.props.activePage === i;

                itemMarkup.push(
                    this.renderListItem(i, 'page', active )
                );
            }
            itemMarkup.push(
                <React.Fragment key = {'p'}>
                    {this.renderListItem(this.props.pageAmount - 2, 'dots')}
                    {this.renderListItem(this.props.pageAmount - 1, 'page')}
                </React.Fragment>
            );
        }

        //if more than 5 pages and active page is NOT one of the last 3 render first page - ... - active page - ... last page
        else if(this.props.pageAmount > 5 && this.props.activePage <= this.props.pageAmount - 4) {
            itemMarkup.push(
                <React.Fragment key = {'p'}>
                    {this.renderListItem(0, 'page', false)}
                    {this.renderListItem(1, 'dots')}
                    {this.renderListItem(this.props.activePage, 'page', true)}
                    {this.renderListItem(this.props.pageAmount - 2, 'dots')}
                    {this.renderListItem(this.props.pageAmount - 1, 'page', false)}
                </React.Fragment>
            );
        }
        //if more than 5 pages and active page IS one of the last 3 render 1 - ... - third to last page - second to last page - last page
        else if(this.props.pageAmount > 5 && this.props.activePage >= this.props.pageAmount - 3) {
            itemMarkup.push(
                <React.Fragment key = {'p'}>
                    {this.renderListItem(0, 'page', false)}
                    {this.renderListItem(1, 'dots')}
                </React.Fragment>
            );
            for(let i = this.props.pageAmount - 3; i < this.props.pageAmount; i++) {
                let active = this.props.activePage === i;

                itemMarkup.push(
                    this.renderListItem(i, 'page', active )
                );

            }
        }


        return itemMarkup;

    }

    render() {

        let prevDisabled = this.props.activePage === 0,
            nextDisabled = this.props.activePage === this.props.pageAmount - 1,
            prevNextActiveClass = 'c-pagination-1__list-item-link--disabled',
            prevDisabledClass = prevDisabled ? prevNextActiveClass : '',
            nextDisabledClass = nextDisabled ? prevNextActiveClass : '';

        return(
            <div className="c-pagination-1">
                <div className="c-pagination-1__wrapper">
                    <ul className="c-pagination-1__list">

                        <li className="c-pagination-1__list-item">
                            <button
                                type="button"
                                className={"c-pagination-1__list-item-link c-pagination-1__list-item-link--prev " + prevDisabledClass}
                                disabled={prevDisabled}
                                onClick={() => this.props.handlePagebrowserClick(this.props.activePage - 1)}
                                aria-label={"Vorherige Seite anzeigen"}
                                aria-hidden={prevDisabled}
                            />
                        </li>
                        {this.renderPageButtons()}

                        <li className="c-pagination-1__list-item">
                            <button
                                type="button"
                                className={"c-pagination-1__list-item-link c-pagination-1__list-item-link--next " + nextDisabledClass}
                                disabled={nextDisabled}
                                onClick={() => this.props.handlePagebrowserClick(this.props.activePage + 1)}
                                aria-label={"Nächste Seite anzeigen"}
                                aria-hidden={nextDisabled}
                            />
                        </li>
                    </ul>

                </div>
            </div>
        )
    }
}

ReactPagination.propTypes = {
    pageAmount: PropTypes.number,
    activePage: PropTypes.number,
    handlePagebrowserClick: PropTypes.func
};
