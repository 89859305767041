export const breakpoints = {
    cinema: 9999,
    desktop: 1921,
    laptop: 1580,
    netbook: 1360,
    menubreak: 1300,
    tablet: 980,
    mobile: 520
}

export const transitionDurations = {
    short: 250,
    medium: 500,
    long: 700
};