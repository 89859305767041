/**
 * Gets the width and height of the current viewport
 * @returns {{width: number, height: number}}
 */
export function getViewport() {
    return {width: document.body.clientWidth, height: window.innerHeight};
}

/**
 * Adds up values from the passed array from index 0 to endIndex
 * @param array - array of numbers
 * @param endIndex - number
 * @returns number
 */
export function sumUpArrayValues(array, endIndex) {
    if(endIndex === -1) {
        return 0;
    }
    else {
        let addedValue = 0;

        for(let u=0; u < endIndex+1; u++) {
            addedValue = addedValue + array[u];
        }

        return addedValue;
    }
}

/**
 * Checks whether the client has the event "ontouchstart".
 * Used to guess whether the user is using touch gestures and disabling all hover styles by removing the class
 * "no-touch" from <html> in entry_global.js
 * @returns {boolean}
 */
export function isTouchDevice() {
    const msGesture = window.navigator && window.navigator.msPointerEnabled && window.MSGesture;
    return ( ( 'ontouchstart' in window ) || (window.DocumentTouch && document instanceof DocumentTouch )) ? true : false;
}

/**
 * Checks for vendor-prefixed names for the transition-end event and returns the one that works in the current browser
 * @returns string - name of transitionEnd even
 */
export function whichTransitionEndEvent() {
    var t;
    var el = document.createElement('fakeelement');
    var transitions = {
        'transition':'transitionend',
        'OTransition':'oTransitionEnd',
        'MozTransition':'transitionend',
        'WebkitTransition':'webkitTransitionEnd'
    };

    for(t in transitions){
        if( el.style[t] !== undefined ) {
            return transitions[t];
        }
    }
}

/**
 * sets cookie matching passed name & path
 * @param {string} cookieName
 * @param {string} cookieValue
 * @param {string} path
 */
export function setCookie(cookieName, cookieValue, path){
    document.cookie = cookieName + "=" + cookieValue + "; path=" + path;
}

/**
 * deletes cookie matching passed name & path
 * @param {string} cookieName
 * @param {string} path
 * @param {string} expireDate (needs to be date in past)
 */
export function deleteCookie(cookieName, path, expireDate){
    document.cookie = cookieName+"=; expires=" + expireDate + "; path=" + path;
}

/**
 * checks if cookie with passed name exists
 * @param {string} cookieName
 * @returns {string|null}
 */
export function checkIfCookieExists(cookieName) {
    var regExpString = "(?:(?:^|.*;\\s*)cookieName\\s*\\=\\s*([^;]*).*$)", //general regex
        regExpGeneratedString = regExpString.replace("cookieName", cookieName), //regex including specific generated cookie-name
        regExp = new RegExp(regExpGeneratedString, "gm"),
        cookieValue = regExp.exec(document.cookie);

    if(cookieValue) {
        return cookieValue[1];
    }
    else {
        return null;
    }
}
