import React from 'react';
import PropTypes from "prop-types";
import Select from "react-select";

export default class ReactFilterSort extends React.Component {

    constructor(props) {
        super(props);
    }

    render() {
        let label = this.props.label ? <label  htmlFor={this.props.inputID}>{this.props.label}</label> : '',
            formfieldClass = this.props.formfieldClass ? 'ph-formfield ' + this.props.formfieldClass : 'ph-formfield';

        return (
            <div className={formfieldClass}>
                <div className="ph-formfield__select-wrap">
                    {label}
                    <Select
                        options = {this.props.options}
                        isMulti = {false}
                        className = "select"
                        isClearable = {false}
                        isSearchable = {false}
                        classNamePrefix = "select"
                        value = {this.props.options.filter(option => option.value === this.props.value)}
                        onChange = {(selectedOption) => this.props.handleSortChange(selectedOption, this.props.properties, this.props.customChangeFunction)}
                    />
                </div>
            </div>

        )
    }

}

ReactFilterSort.propTypes = {
    handleSortChange: PropTypes.func.isRequired,
    options: PropTypes.arrayOf(PropTypes.shape({
        value: PropTypes.oneOfType([
            PropTypes.string,
            PropTypes.number
        ]).isRequired,
        direction: PropTypes.oneOf(["up", "down"]).isRequired,
        label: PropTypes.string.isRequired,
        property: PropTypes.number.isRequired
    })).isRequired,
    properties: PropTypes.arrayOf(PropTypes.arrayOf(PropTypes.string)).isRequired,
    inputID: PropTypes.string.isRequired,
    value: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number
    ]).isRequired,
    label: PropTypes.string,
    customChangeFunction: PropTypes.func
};
