import { Fancybox, Carousel, Panzoom } from "@fancyapps/ui";
import "@fancyapps/ui/dist/fancybox.css";
import de from "@fancyapps/ui/src/Fancybox/l10n/de";

let $b,
    $components;

$b = $('body');
$components = $b.find('.js-fancybox-image:not(.js-fancybox-image--initialized)');

if($components.length > 0) {

   Fancybox.bind('[data-fancybox="gallery"]', {
       l10n: de,
       Toolbar: {
           display: [
               { id: "prev", position: "center" },
               { id: "counter", position: "center" },
               { id: "next", position: "center" },
               "zoom",
               "slideshow",
               "fullscreen",
               "download",
               "thumbs",
               "close",
           ],
       },

       on: {
           load: function () {
               $components.addClass('js-fancybox-image--initialized')
           }
       }
    });
}


