import {getViewport} from "../../../javascript/helpers";
import {breakpoints} from "../../../javascript/config";
import "../../../javascript/shariff.complete";

let $w,
    $b,
    $modules,
    viewportWidth;

$w = $(window);
$b = $('body');
$modules = $b.find('.js-shariff:not(.js-shariff--initialized)');

if($modules.length > 0) {

    $modules.each(function (){

        var $thisModule = $(this),
            $mobileTrigger = $thisModule.find('.js-mobile-trigger');

        $mobileTrigger.on('click', function (){

            if(!$thisModule.hasClass('js-open')) {

                $thisModule.addClass('js-open');

            }

            else {

                $thisModule.removeClass('js-open');

            }

        });

        $thisModule.addClass('js-shariff--initialized');

    });

    $w.on('_resizeEnd', function () {

        classHandler();

    });

}

function classHandler() {

    updateViewport();

    if(viewportWidth > breakpoints.tablet) {

        if($modules.hasClass('js-open')) {

            $modules.removeClass('js-open');

        }

    }

}

function updateViewport() {

    var $viewport = getViewport();
    viewportWidth = $viewport.width;

}
