import React from "react";
import ReactDOM from 'react-dom';
import ReactEventMap from "./reactComponents/ReactEventMap";


let $b = $('body'),
    $reactWrapper = $b.find('.js-m-react-event-map .js-react-wrapper');

$reactWrapper.each(function(index){
    initReactEventMap(this, index);
});

function initReactEventMap(reactContainer, index) {
    let $reactContainer = $(reactContainer),
        $currentModule = $reactContainer.parents('.js-m-react-event-map'),
        dataUrl = $reactContainer.data('url'),
        dataApi = $reactContainer.data('api'),
        dataPlzUrl = $reactContainer.data('plz-url'),
        loadedData = [],
        plzData = [];

    $.when(
        $.getJSON(dataUrl, function(data){
            loadedData = data;
        }).fail(function( jqxhr, textStatus, error ) {
            let err = textStatus + ", " + error;
            console.log( "Request Failed: " + err );
        }),
        $.getJSON(dataPlzUrl, function(data){
            plzData = data;
        }).fail(function( jqxhr, textStatus, error ) {
            let err = textStatus + ", " + error;
            console.log( "Request Failed: " + err );
        })
    ).then(function() {
        ReactDOM.render(
            <ReactEventMap
                data={loadedData}
                apiKey = {dataApi}
                plzData = {plzData}
                moduleIndex = {index}
            />
            , reactContainer);
        $currentModule.removeClass('js-loading');
    });

}

